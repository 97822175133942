import { useCookies } from 'react-cookie';
import { WwwCookies } from '../../../enums/storage';
import { VITE_COOKIE_DOMAIN } from '../../../config/env';
import { ONE_YEAR_IN_SECONDS } from '../../../constants/general';

const options = {
  path: '/',
  domain: VITE_COOKIE_DOMAIN,
  maxAge: ONE_YEAR_IN_SECONDS,
};

export const useProspectCookies = () => {
  const [_, setCookie] = useCookies([...Object.values(WwwCookies)]);

  const handleCookies = (cookies: Record<WwwCookies, string>) => {
    setCookie(WwwCookies.Name, cookies[WwwCookies.Name], options);
    setCookie(WwwCookies.Address, cookies[WwwCookies.Address], options);
    setCookie(WwwCookies.Phone, cookies[WwwCookies.Phone], options);
  };

  return { handleCookies };
};
