import * as Sentry from '@sentry/react';
import { version } from '../package.json';
import { VITE_SENTRY_DSN, VITE_APP_ENV, VITE_SENTRY_ENABLED, VITE_BRAND } from './config/env';
import { LocalStorage } from './enums/storage';

export const initSentryDefaultOptions = {
  dsn: VITE_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing()],
  release: version,
  environment: VITE_APP_ENV,
  enabled: VITE_SENTRY_ENABLED === 'true',
};

export const initSentry = (initSentryOptions = initSentryDefaultOptions) => {
  Sentry.init({
    dsn: initSentryOptions.dsn,
    integrations: initSentryOptions.integrations,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release: initSentryOptions.release,
    environment: initSentryOptions.environment,
    enabled: initSentryOptions.enabled,
  });
};

export const initBrand = () => {
  localStorage.setItem(LocalStorage.Brand, String(VITE_BRAND));
};
