import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { Step } from '../../../layout/Step';
import { StepId } from '../../../enums/schema';
import { useStepNavigation } from '../../../hooks/useStepNavigation';
import { StepTitle } from '../../../components/StepTitle';
import { useContactInfo } from './controller';
import { CalculationScreen } from './components/CalculationScreen';
import { CAPTCHA_ACTIVATED, STATIC_MAPS_BASE_URL } from '../../../constants/general';
import { ContactInfoForm } from './components/Form';
import { VITE_GOOGLE_API_KEY } from '../../../config/env';
import { Place } from '@lawnstarter/ls-react-common/types';
import { useTrackPayload } from '../../../hooks/useTrackPayload';
import { Events } from '../../../enums/events';
import { trackContactSubmitted, trackInitiateCheckout } from '../../../service/segment/trackers';
import { lsI18NService } from '../../../service';

const stepId = StepId.ContactInfo;

export const ContactInfo = () => {
  const navigate = useNavigate();
  const { currentStep, nextStep, vertical } = useStepNavigation({ stepId });
  const [showCalculationScreen, setShowCalculationScreen] = useState(false);
  const { onSubmit, onSetAddress, setAddressInput, form, mutation, delayIsOver, place } = useContactInfo({
    setShowCalculationScreen,
  });
  const [captchaVerification, setCaptchaVerification] = useState<boolean>(false);
  const [staticMap, setStaticMap] = useState<string | null>(null);

  const { payloadBuilder: contactSubmittedPayload } = useTrackPayload(Events.ContactSubmitted);
  const { payloadBuilder: initiateCheckoutPayload } = useTrackPayload(Events.InitiateCheckout);

  useEffect(() => {
    const prospectId = mutation.data?.createProspect?.prospect.id;

    const shouldNavigate = CAPTCHA_ACTIVATED
      ? prospectId && delayIsOver && captchaVerification
      : prospectId && delayIsOver;

    if (shouldNavigate) {
      const checkoutPayload = initiateCheckoutPayload();
      checkoutPayload && trackInitiateCheckout(checkoutPayload);

      const contactPayload = contactSubmittedPayload();
      contactPayload && trackContactSubmitted(contactPayload);

      navigate(`/${currentStep.type}/${prospectId}/${nextStep?.id}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    navigate,
    currentStep.type,
    nextStep?.id,
    mutation.data?.createProspect?.prospect.id,
    delayIsOver,
    captchaVerification,
  ]);

  const onPress = async () => {
    if (!place) {
      form.control.setError('address1', {
        type: 'required',
        message: lsI18NService.t('contactInfo.addressSelectError'),
      });
      return;
    }

    if (!place.street_number) {
      form.control.setError('address1', {
        type: 'required',
        message: lsI18NService.t('formErrors.invalidStreetNumber'),
      });
      return;
    }

    if (!form.isValid) {
      form.trigger();
      return;
    }

    if (mutation.isError) {
      mutation.reset();
      setCaptchaVerification(false);
    }

    setShowCalculationScreen(true);
  };

  useEffect(() => {
    if (!captchaVerification) {
      return;
    }

    onSubmit();
  }, [captchaVerification, onSubmit]);

  const onCaptchaVerified = (_token: string | null) => setCaptchaVerification(true);

  const getFormattedAddress = ({ street, city, state, zip, country }: Place) => {
    if (!place) return '';

    return [street, city, state, zip, country].join(', ');
  };

  useEffect(() => {
    if (!place) return;

    const buildMapUrl = (address: string) => {
      const mapParams = {
        center: address,
        zoom: '16',
        size: '600x300',
        maptype: 'satellite',
        key: VITE_GOOGLE_API_KEY,
      };

      return `${STATIC_MAPS_BASE_URL}?${new URLSearchParams(mapParams).toString()}`;
    };

    const formattedAddress = getFormattedAddress(place);
    const mapUrl = buildMapUrl(formattedAddress);

    // Preload the map
    const img = new Image();
    img.src = mapUrl;
    img.onload = () => setStaticMap(mapUrl);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [place]);

  useEffect(() => {
    mutation.isError && setShowCalculationScreen(false);
  }, [mutation.isError]);

  return showCalculationScreen ? (
    <CalculationScreen
      onCaptchaVerified={onCaptchaVerified}
      zipCode={place?.zip ?? ''}
      staticMap={staticMap}
      address={place ? getFormattedAddress(place) : ''}
    />
  ) : (
    <Step>
      <Step.Body>
        <Step.Content>
          <StepTitle stepId={stepId} vertical={lsI18NService.t(`${vertical}.title`)} />
        </Step.Content>
        <ContactInfoForm
          control={form.control}
          onPress={onPress}
          onSetAddress={onSetAddress}
          stepId={stepId}
          setAddressInput={setAddressInput}
          clearErrors={form.clearErrors}
          trigger={form.trigger}
        />
      </Step.Body>
    </Step>
  );
};
