import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import { useApplyPromoCodeMutation } from '../../../store/modules/prospect/api';
import { QueryString } from '../../../enums/storage';
import { updateApp } from '../../../store/modules/app/slice';
import { updateProspect } from '../../../store/modules/prospect/slice';

export const useDeal = () => {
  const [applyPromoCode, { isSuccess: promoCodeApplied }] = useApplyPromoCodeMutation();
  const { hashId: prospectId } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const dealParam = searchParams.get(QueryString.PromoCode);

  useEffect(() => {
    if (dealParam) {
      applyPromoCode({ prospectId, promoCode: dealParam });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealParam]);

  useEffect(() => {
    if (promoCodeApplied) {
      dispatch(updateApp({ promoCode: dealParam }));
      dispatch(updateProspect({ promoCode: dealParam }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoCodeApplied]);
};
