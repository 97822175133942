import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../..';

export enum HeaderVariants {
  DetailedBadgePhone = 'detailed-badge-phone',
}

interface AppState {
  shouldShowAddress: boolean;
  shouldShowDetails: boolean;
  shouldShowHeader: boolean;
  headerRightSlot: HeaderVariants | null;
  turnstileToken: string | null;
  segmentAnonymousId: string | null;
  error: string | null;
  promoCode: string | null;
}

const initialState: AppState = {
  shouldShowAddress: false,
  shouldShowDetails: true,
  shouldShowHeader: true,
  headerRightSlot: null,
  turnstileToken: null,
  segmentAnonymousId: null,
  error: null,
  promoCode: null,
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateApp(state, { payload }: PayloadAction<Partial<typeof initialState>>) {
      return { ...state, ...payload };
    },
  },
});

export const { updateApp } = slice.actions;
export default slice.reducer;
export const selectApp = (state: RootState) => state.app;
