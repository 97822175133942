import { Spinner, Text } from '@lawnstarter/ls-react-common/atoms';
import { lsI18NService } from '../../../../../service';
import { StyledContainer, StyledContent, StyledSpan, titleStyle } from './styles';
import { useDispatch } from 'react-redux';
import { CAPTCHA_ACTIVATED, SOCIAL_PROOF_BASE_VALUE } from '../../../../../constants/general';
import { useEffect, useMemo } from 'react';
import { Turnstile } from '../../../../../components/Turnstile';
import { updateApp } from '../../../../../store/modules/app/slice';
import { METADATA } from '../../../../../constants/metadata';
import { VITE_BRAND } from '../../../../../config/env';
import { Brand } from '@lawnstarter/ls-react-common/enums';
import { useStepNavigation } from '../../../../../hooks/useStepNavigation';
import { StepId } from '../../../../../enums/schema';
import { Titles } from '../../../../../enums/titles';
import { ContactInfoStep } from '../../../../../types/schema';

interface Props {
  onCaptchaVerified?: (token: string | null) => void;
  staticMap: string | null;
  zipCode: string;
  address: string;
}

export const CalculationScreen = ({ onCaptchaVerified, staticMap, zipCode, address }: Props) => {
  const dispatch = useDispatch();
  const zipLastDigit = parseInt(zipCode, 10) % 10;
  const socialProof = zipLastDigit ? zipLastDigit + SOCIAL_PROOF_BASE_VALUE : SOCIAL_PROOF_BASE_VALUE;
  const { displayName: brand } = METADATA[VITE_BRAND as Brand];
  const { currentStep } = useStepNavigation({ stepId: StepId.ContactInfo });

  const onVerify = (token: string | null) => {
    onCaptchaVerified && onCaptchaVerified(token);
  };

  const title = useMemo(() => {
    const contactInfoStep = currentStep as ContactInfoStep;
    const title = contactInfoStep.options?.calculationScreen?.title || Titles.Quoting;
    return lsI18NService.t(title);
  }, [currentStep]);

  // Automatically calls onVerify when captcha is not enabled
  useEffect(() => {
    !CAPTCHA_ACTIVATED && onVerify(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CAPTCHA_ACTIVATED]);

  useEffect(() => {
    dispatch(updateApp({ shouldShowHeader: false }));

    return () => {
      dispatch(updateApp({ shouldShowHeader: true }));
    };
  }, [dispatch]);

  return (
    staticMap && (
      <StyledContainer>
        <Spinner
          innerElement={<img alt={lsI18NService.t('calculationScreen.googlePhoto', { address })} src={staticMap} />}
          innerElementPadding={10}
          animating
          hidesWhenStopped={false}
          size={250}
        />

        <StyledContent>
          <Text variant="headlineSmall" style={titleStyle}>
            {title}
          </Text>
        </StyledContent>

        <StyledContent>
          <Text variant="bodyLarge">
            <StyledSpan>{lsI18NService.t('socialProof.neighbors', { neighbors: socialProof })}</StyledSpan>
            &nbsp;
            {lsI18NService.t('socialProof.inZip', { zip: zipCode, brand })}
          </Text>
        </StyledContent>

        {CAPTCHA_ACTIVATED && <Turnstile onVerify={onVerify} hideOnVerified={true} />}
      </StyledContainer>
    )
  );
};
