import { theme } from '@lawnstarter/ls-react-common';
import tw from 'tailwind-styled-components';

export const StyledDetailedBadge = tw.div`
  w-full md:max-w-52 my-4
`;

export const styleDetailedBadge = {
  wrapper: {
    borderColor: theme.colors.gray.primary,
    borderStyle: 'solid',
    borderWidth: 1.25,
    borderRadius: 5,
  },
};
