import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { VITE_MY_LOGIN_URL } from '../config/env';

import { StepId } from '../enums/schema';

interface usePageRefreshProps {
  stepId: StepId;
}

export function usePageRefresh({ stepId }: usePageRefreshProps) {
  const navigation = _.first(window.performance.getEntriesByType('navigation')) as PerformanceNavigationTiming;
  const step = navigation?.name?.split('/')?.pop();

  const isStepMatching = useMemo(() => step === stepId, [stepId, step]);
  const isReload = useMemo(() => navigation?.type === 'reload', [navigation?.type]);

  useEffect(() => {
    isReload && isStepMatching && window.location.replace(VITE_MY_LOGIN_URL);
  }, [isReload, isStepMatching]);
}
