import { Text } from '@lawnstarter/ls-react-common/atoms';
import { CartProps } from '../..';
import { styleDescription, StyledContainer, styleTitle } from '../../styles';
import { lsI18NService } from '../../../../service';
import { centsToDollar } from '../../../../helpers/currency';
import { StyledStroke } from '../../../../common/styles';
import { TRUST_AND_SAFETY_FEE } from '../../../../constants/general';
import { PriceDetail } from '../PriceDetail';
import { PromocodeType } from '../../../../enums/promocodes';
import { useMemo } from 'react';

export const PromoDetails = ({ promocode, price }: CartProps) => {
  const regularPrice = centsToDollar(price);
  const fee = centsToDollar(TRUST_AND_SAFETY_FEE);
  const total = price + TRUST_AND_SAFETY_FEE;

  const isCredit = promocode?.type === PromocodeType.Credit;

  const discount = useMemo(
    () => (isCredit ? promocode.amount : total - promocode!.amount),
    [isCredit, promocode, total],
  );

  return (
    promocode && (
      <>
        <PriceDetail label={lsI18NService.t('cart.regularPrice')} price={`$${regularPrice}`} />
        {isCredit && (
          <PriceDetail label={lsI18NService.t('serviceTrust')} price={`-$${centsToDollar(TRUST_AND_SAFETY_FEE)}`} />
        )}
        <PriceDetail label={lsI18NService.t('cart.discount')} price={`-$${centsToDollar(discount)}`} />
        <StyledStroke className="mb-2 md:block " />
        <StyledContainer>
          <Text style={styleTitle} variant="titleMedium">
            {lsI18NService.t(`cart.detailsTitle`)}
          </Text>
          <Text style={styleTitle} variant="titleMedium">
            ${centsToDollar(total - discount)}
          </Text>
        </StyledContainer>
        <Text style={styleDescription} variant="bodySmall">
          {lsI18NService.t('cart.followingServicesAre', { amount: regularPrice })} + ${fee} &nbsp;
          {lsI18NService.t('serviceTrust')}
        </Text>
      </>
    )
  );
};
