import { Loader } from '@googlemaps/js-api-loader';
import { VITE_GOOGLE_API_KEY } from '../config/env';
import { useEffect, useState } from 'react';

const defaultPredictionConfig = {
  componentRestrictions: { country: 'us' },
  types: ['address'],
};

export function usePlaces() {
  const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService>();
  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService>();
  const [prediction, setPrediction] = useState<google.maps.places.PlaceResult | null>(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const loader = new Loader({ apiKey: VITE_GOOGLE_API_KEY });

    const importPlacesLibrary = async () => {
      const places = await loader.importLibrary('places');
      const autocompleteLibrary = new places.AutocompleteService();
      const map = new google.maps.Map(document.createElement('div'));
      const placesLibrary = new places.PlacesService(map);
      setAutocompleteService(autocompleteLibrary);
      setPlacesService(placesLibrary);
    };

    importPlacesLibrary().then(() => setIsReady(true));
  }, []);

  const getPlaceByInput = (input: string) => {
    autocompleteService?.getPlacePredictions(
      { ...defaultPredictionConfig, input },
      /* istanbul ignore next */
      (predictions) => {
        if (predictions && predictions[0]) {
          placesService?.getDetails(
            { placeId: predictions[0].place_id },
            (detailedPrediction) => void setPrediction(detailedPrediction),
          );
        }
      },
    );
  };

  const getPlaceById = (placeId: string) =>
    void placesService?.getDetails(
      { placeId },
      /* istanbul ignore next */
      (detailedPrediction) => void setPrediction(detailedPrediction),
    );

  return { prediction, isReady, getPlaceByInput, getPlaceById };
}
