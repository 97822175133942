import { Controller } from 'react-hook-form';
import { Accordion } from '@lawnstarter/ls-react-common/atoms';
import { CheckboxOptions } from '@lawnstarter/ls-react-common/molecules';
import { FormProps } from './types';
import { StyledContent, accordionStyle } from './styles';
import { lsI18NService } from '../../../../service';
import { useCallback } from 'react';
import { useTrackPayload } from '../../../../hooks/useTrackPayload';
import { Events } from '../../../../enums/events';
import { trackHelpArticleExpanded } from '../../../../service/segment/trackers';

export const Form = ({ control, items }: FormProps) => {
  const { payloadBuilder } = useTrackPayload(Events.HelpArticleExpanded);
  const onOpen = useCallback(
    (articleTitle: string) => {
      const payload = payloadBuilder({ articleTitle, helpContext: 'scheduling' });
      payload && trackHelpArticleExpanded(payload);
    },
    [payloadBuilder],
  );

  return (
    <>
      {items?.map(({ type, estimateId }, i) => {
        return (
          <Accordion
            key={`${estimateId}-${i}`}
            title={lsI18NService.t(`extraServices.${type}.title`) || type}
            trackID={`items-checkbox-${estimateId}`}
            style={accordionStyle}
            onOpen={() => onOpen(lsI18NService.t(`extraServices.${type}.title`) || type)}
            right={() => (
              <Controller
                control={control}
                name={type}
                render={() => (
                  <CheckboxOptions testID={`id-${type}`} control={control} options={[{ value: type, label: '' }]} />
                )}
              />
            )}
          >
            <StyledContent>{lsI18NService.t(`extraServices.${type}.description`) || type}</StyledContent>
          </Accordion>
        );
      })}
    </>
  );
};
