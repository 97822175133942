import { Banner } from '../../../../../components/Banner';
import { lsI18NService } from '../../../../../service';
import { AccountAndPaymentStep } from '../../../../../types/schema';
import { CompactFaq } from '../CompactFaq';

import { StyledItem } from './styles';

interface Props {
  className?: string;
  currentStep: AccountAndPaymentStep;
}

export const ToSBanner = ({ currentStep, className }: Props) => {
  return (
    <Banner
      className={className}
      title={lsI18NService.t('accountAndPayment.tos.title')}
      icon="pin-outline"
      headerClassName="bg-white border-b-2 border-gray-200"
      headerSlot={() => <CompactFaq />}
    >
      <ul>
        {(currentStep.options?.tos as []).map((police) => (
          <StyledItem key={police} data-testid="tos-polices">
            {lsI18NService.t(`accountAndPayment.tos.polices.${police}`)}
          </StyledItem>
        ))}
      </ul>
    </Banner>
  );
};
