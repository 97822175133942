import { Accordion, Text } from '@lawnstarter/ls-react-common/atoms';
import { Brand } from '@lawnstarter/ls-react-common/enums';
import { List } from 'react-native-paper';

import { lsI18NService } from '../../../../../service';
import { PhoneDetailedBadge } from '../../../../../components/PhoneDetailedBadge';
import { METADATA } from '../../../../../constants/metadata';
import { VITE_BRAND } from '../../../../../config/env';
import { useTrackPayload } from '../../../../../hooks/useTrackPayload';
import { useCallback } from 'react';
import { trackHelpArticleExpanded } from '../../../../../service/segment/trackers';
import _ from 'lodash';
import { Events } from '../../../../../enums/events';

export const Questions = () => {
  const { displayName } = METADATA[VITE_BRAND as Brand];
  const { payloadBuilder } = useTrackPayload(Events.HelpArticleExpanded);
  const onOpen = useCallback(
    (articleTitle: string) => {
      const payload = payloadBuilder({ articleTitle, helpContext: 'scheduling' });
      payload && trackHelpArticleExpanded(payload);
    },
    [payloadBuilder],
  );

  return (
    <>
      {[...Array(5).keys()].map((_, i) => (
        <Accordion
          key={i}
          onOpen={() => onOpen(lsI18NService.t(`scheduling.faq.question${i + 1}.title`))}
          title={lsI18NService.t(`scheduling.faq.question${i + 1}.title`)}
          trackID={`faq-scheduling-${i}`}
        >
          <ul>
            <li className="pl-2">
              <List.Item
                left={() => <Text>&#x25CF;</Text>}
                titleNumberOfLines={0}
                title={lsI18NService.t(`scheduling.faq.question${i + 1}.content`, { brand: displayName })}
              />
            </li>
          </ul>
        </Accordion>
      ))}
      <Accordion
        onOpen={() => onOpen(lsI18NService.t(`scheduling.faq.additionalQuestion.title`))}
        title={lsI18NService.t(`scheduling.faq.additionalQuestion.title`)}
        trackID={`faq-scheduling-additional-question`}
        testID={`faq-scheduling-additional-question`}
      >
        <div className="h-20">
          <PhoneDetailedBadge
            trackID="scheduling-faq-phone-detailed-badge"
            testID="scheduling-faq-phone-detailed-badge"
          />
        </div>
      </Accordion>
    </>
  );
};
