import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { isVertical } from '../../../helpers/vertical';
import { useStepNavigation } from '../../../hooks/useStepNavigation';
import { StepId } from '../../../enums/schema';
import { QueryString } from '../../../enums/storage';

export const useVerticalSelection = () => {
  const navigate = useNavigate();
  const [_, setSearchParams] = useSearchParams();
  const { goToNextStep } = useStepNavigation({ stepId: StepId.VerticalSelection });

  const [vertical, setVertical] = useState<string | null>(null);

  const onVerticalChange = useCallback(
    (intent: string) => {
      setVertical(intent);
      isVertical(intent) && setSearchParams({ [QueryString.Intent]: intent });
    },
    [setSearchParams],
  );

  const onContinue = useCallback(() => {
    const isValidVertical = vertical && isVertical(vertical);
    const shouldAdvance = isValidVertical && goToNextStep;

    shouldAdvance && navigate(goToNextStep);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vertical]);

  return { onVerticalChange, onContinue, vertical };
};
