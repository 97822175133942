import { useEffect } from 'react';
import { lsSegmentService } from '../..';
import { useLocation } from 'react-router-dom';
import { useTrackPayload } from '../../../hooks/useTrackPayload';
import { Events } from '../../../enums/events';
import { usePromoCode } from '../../../hooks/usePromoCode';
import { trackPageViewed } from '../trackers';

export function useSegmentPage() {
  const location = useLocation();
  const { payloadBuilder } = useTrackPayload(Events.PageViewed);
  const { promocode } = usePromoCode();

  useEffect(() => {
    const payload = {
      referrer: document.referrer,
      ...location,
    };

    lsSegmentService.page(payload);

    const eventPayload = payloadBuilder({ promocode: promocode?.id ?? null });
    eventPayload && trackPageViewed(eventPayload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
}
