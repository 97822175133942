import tw from 'tailwind-styled-components';

export const StyledWrapper = tw.ul`
  w-full flex flex-wrap justify-between mt-2 mb-4 md:flex-col md:text-center
`;

export const StyledPrice = tw.li`
  font-bold text-sm md:text-2xl md:mb-2
`;

export const StyledTimes = tw.li`
  text-[var(--grayPrimary)] text-sm md:text-base
`;
