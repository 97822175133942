import { Text } from '@lawnstarter/ls-react-common/atoms';

import { lsI18NService } from '../../../../../service';
import LawnTreatmentMonth1Photo from '../../../../../assets/lawn_treatment_month_01.png';
import LawnTreatmentMonth5Photo from '../../../../../assets/lawn_treatment_month_05.png';
import LawnTreatmentMonth11Photo from '../../../../../assets/lawn_treatment_month_11.png';
import { StyledItem, StyledItemDescription, StyledScrollable, StyledScroller, descriptionStyle } from './styles';
import { NavDots } from '../NavDots';

export const TreatmentCarousel = () => {
  return (
    <>
      <StyledScroller>
        <StyledScrollable>
          <StyledItem>
            <img src={LawnTreatmentMonth1Photo} alt={lsI18NService.t('lawnTreatment.carousel.month1')} />
            <StyledItemDescription>{lsI18NService.t('lawnTreatment.carousel.month1')}</StyledItemDescription>
            <NavDots dots={3} selectedDot={1} className="lg:hidden" />
          </StyledItem>
          <StyledItem>
            <img src={LawnTreatmentMonth5Photo} alt={lsI18NService.t('lawnTreatment.carousel.month5')} />
            <StyledItemDescription>{lsI18NService.t('lawnTreatment.carousel.month5')}</StyledItemDescription>
            <NavDots dots={3} selectedDot={2} className="lg:hidden" />
          </StyledItem>
          <StyledItem className="lg:col-span-2">
            <img src={LawnTreatmentMonth11Photo} alt={lsI18NService.t('lawnTreatment.carousel.month11')} />
            <StyledItemDescription>{lsI18NService.t('lawnTreatment.carousel.month11')}</StyledItemDescription>
            <NavDots dots={3} selectedDot={3} className="lg:hidden" />
          </StyledItem>
        </StyledScrollable>
      </StyledScroller>
      <Text variant="bodyMedium" style={descriptionStyle}>
        {lsI18NService.t('lawnTreatment.carousel.customServicesBySeason')}
      </Text>
    </>
  );
};
