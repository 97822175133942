import { Text } from '@lawnstarter/ls-react-common/atoms';
import { styleDescription, StyledContainer, StyledDetail, styleTitle } from '../../styles';
import { lsI18NService } from '../../../../service';
import { centsToDollar } from '../../../../helpers/currency';
import { TRUST_AND_SAFETY_FEE } from '../../../../constants/general';

export const RegularDetails = ({ price }: { price: number }) => {
  return (
    <StyledDetail>
      <StyledContainer>
        <Text style={styleTitle} variant="titleMedium">
          {lsI18NService.t(`cart.title`)}
        </Text>
        <Text style={styleTitle} variant="titleMedium">
          ${centsToDollar(price)}
        </Text>
      </StyledContainer>

      <Text style={styleDescription} variant="bodySmall">
        + ${centsToDollar(TRUST_AND_SAFETY_FEE)} {lsI18NService.t('serviceTrust')}
      </Text>
    </StyledDetail>
  );
};
