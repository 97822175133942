import { PromoCode } from '../../types/graphql';
import { StyledCart } from './styles';
import { PromoDetails } from './components/PromoDetails';
import { RegularDetails } from './components/RegularDetails';

export interface CartProps {
  promocode: PromoCode | null;
  price: number;
}

export const Cart = ({ promocode, price }: CartProps) => {
  return (
    <StyledCart>
      {promocode ? <PromoDetails promocode={promocode} price={price} /> : <RegularDetails price={price} />}
    </StyledCart>
  );
};
