import tw from 'tailwind-styled-components';
import { setFontWeight, theme } from '@lawnstarter/ls-react-common';

export const StyledList = tw.ul`
  my-6
`;

export const StyledItem = tw.li`
  flex items-center my-3 gap-2
`;

export const titleStyle = { ...setFontWeight('600'), display: 'block', marginVertical: theme.spacing.s4 };
