import { useEffect, useMemo, useState } from 'react';
import { Nullable } from '@lawnstarter/ls-react-common/types';

import { StepId, StepType, Vertical } from '../enums/schema';
import { useSchema } from './useSchema';
import { To, useLocation, useParams } from 'react-router-dom';
import { Step } from '../types/schema';
export interface useStepNavigationArgs {
  stepId: StepId;
}

export interface useStepNavigationResponse {
  steps: Step[];
  currentStep: Step;
  percentageConcluded: number;
  setToPathname: React.Dispatch<React.SetStateAction<Nullable<string>>>;
  resetToPathname: () => void;
  vertical: Vertical;
  nextStep?: Step;
  goToNextStep?: To;
  hashId?: string;
}

export function useStepNavigation({ stepId }: useStepNavigationArgs): useStepNavigationResponse {
  const { schema, vertical } = useSchema();
  const { search } = useLocation();
  const { hashId } = useParams();
  const [toPathname, setToPathname] = useState<Nullable<string>>(null);

  const stepType = useMemo(
    () => schema.steps.find(({ id }) => id === stepId)?.type || StepType.Cart,
    [schema.steps, stepId],
  );

  const steps = useMemo(() => schema.steps.filter(({ type }) => type === stepType), [schema.steps, stepType]);

  const indexOf = useMemo(() => {
    const index = steps.findIndex(({ id }) => id === stepId);
    return index < 0 ? 0 : index;
  }, [stepId, steps]);

  const currentStep = steps[indexOf];

  const nextStep: Step | undefined = steps[indexOf + 1];

  const percentageConcluded = Math.round(100 * (indexOf / steps.length));

  const resetToPathname = () => nextStep && setToPathname(`/${stepType}${hashId ? `/${hashId}/` : `/`}${nextStep.id}`);

  const goToNextStep = useMemo(
    () => (toPathname ? ({ pathname: toPathname, search } as To) : undefined),
    [search, toPathname],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => resetToPathname(), []);

  return {
    steps,
    currentStep,
    nextStep,
    goToNextStep,
    setToPathname,
    resetToPathname,
    percentageConcluded,
    vertical,
    hashId,
  };
}
