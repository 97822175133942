import { Icon, Text } from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { StyledIcon } from './styles';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';
import { useCallback } from 'react';
import { lsI18NService } from '../../../../../service';
import { Questions } from '../Questions';
import { trackHelpViewed } from '../../../../../service/segment/trackers';
import { Events } from '../../../../../enums/events';
import { useTrackPayload } from '../../../../../hooks/useTrackPayload';

export const CompactFaq = () => {
  const { colors, spacing } = useAppTheme();
  const modal = useModal(ModalTemplate.GENERIC);
  const { payloadBuilder } = useTrackPayload(Events.HelpViewed);

  const openModal = useCallback(() => {
    const payload = payloadBuilder({ helpContext: 'scheduling' });
    payload && trackHelpViewed(payload);

    modal.show({
      animationType: 'fade',
      content: () => (
        <>
          <Text variant="headlineMedium">{lsI18NService.t('faq')}</Text>
          <br />
          <Questions />
        </>
      ),
      buttonText: lsI18NService.t('ok'),
    });
  }, [modal, payloadBuilder]);

  return (
    <StyledIcon onClick={openModal} data-testid="payment-faq-mobile" className="align-middle">
      <Icon name="help-circle-outline" color={colors.primary} size={spacing.s8} />
    </StyledIcon>
  );
};
