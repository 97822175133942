import { useSelector } from 'react-redux';
import { VITE_LEGACY_SIGNUP_PORTAL } from '../../../config/env';
import { useSchema } from '../../../hooks/useSchema';
import { selectProperty } from '../../../store/modules/property/slice';
import { selectProspect } from '../../../store/modules/prospect/slice';

export const useWwwLead = () => {
  const { vertical } = useSchema();
  const property = useSelector(selectProperty);
  const prospect = useSelector(selectProspect);

  const params = new URLSearchParams(new URL(VITE_LEGACY_SIGNUP_PORTAL).search);
  params.append('phone', property.phone);
  params.append('name', property.name);
  params.append('intent', vertical);
  params.append('googlePlace', 'true');
  params.append('lead', '1');
  params.append('address', `${property.address1}, ${property.city}, ${property.state} ${property.zip}, USA`);
  params.append('propertyId', prospect.lsApiPropertyId);

  const landscapingUrl = `${VITE_LEGACY_SIGNUP_PORTAL}?${params.toString()}`;

  return {
    landscapingUrl,
  };
};
