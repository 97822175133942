import { Vertical } from '../enums/schema';
import { getVertical } from '../helpers/vertical';
import { schema as mowingSchema } from '../schemas/mowing';
import { schema as leafRemovalSchema } from '../schemas/leafRemoval';

export function useSchema() {
  const vertical = getVertical();
  const schemas = {
    [Vertical.Mowing]: mowingSchema,
    [Vertical.LeafRemoval]: leafRemovalSchema,
  };

  return { schema: schemas[vertical], vertical };
}
