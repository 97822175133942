import tw from 'tailwind-styled-components';

export const StyledStep = tw.section`
  flex py-4 flex-col flex-1 w-full
`;

export const StyledHeader = tw.div`
  flex-col py-2
`;

export const StyledBody = tw.div`
  flex flex-col flex-1 gap-x-8 -mt-4
  md:mt-8 md:flex-row
`;

export const StyledContent = tw.div`
  w-full mb-6
  md:w-1/2 md:text-left
`;

export const StyledForm = tw.div`
  w-full flex flex-col flex-1 pb-20
  md:w-1/2 md:pb-0
`;

export const StyledFooter = tw.div`
  w-full flex flex-col items-center fixed bottom-0 left-0 p-4 bg-white z-2 shadow-top
  md:relative md:flex-initial md:shadow-none
`;

export const StyledAction = tw.div`
  w-full
  md:max-w-[330px]
`;
