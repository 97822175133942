import { QuoteQuestions, QuoteQuestionsKeys, QuoteQuestionFieldType } from '../enums/quoteQuestions';
import { Question } from '../types/questions';

export const whereToActQuestion: Question = {
  label: QuoteQuestions.WhereToAct,
  fields: [
    { key: QuoteQuestionsKeys.FullYard, type: QuoteQuestionFieldType.Checkbox },
    { key: QuoteQuestionsKeys.FrontYard, type: QuoteQuestionFieldType.Checkbox },
    { key: QuoteQuestionsKeys.BackYard, type: QuoteQuestionFieldType.Checkbox },
    { key: QuoteQuestionsKeys.LeftYard, type: QuoteQuestionFieldType.Checkbox },
    { key: QuoteQuestionsKeys.RightYard, type: QuoteQuestionFieldType.Checkbox },
  ],
};

export const disposeDebrisQuestion: Question = {
  label: QuoteQuestions.DisposeDebris,
  fields: [{ key: QuoteQuestionsKeys.DesiredHaulaway, type: QuoteQuestionFieldType.Radio }],
};

export const removeLeavesQuestion: Question = {
  label: QuoteQuestions.RemoveLeaves,
  fields: [{ key: QuoteQuestionsKeys.DesiredBedCleanup, type: QuoteQuestionFieldType.Radio }],
};

export const removeDebrisQuestion: Question = {
  label: QuoteQuestions.RemoveDebris,
  fields: [
    { key: QuoteQuestionsKeys.HasDryLeaves, type: QuoteQuestionFieldType.Checkbox },
    { key: QuoteQuestionsKeys.HasWetLeaves, type: QuoteQuestionFieldType.Checkbox },
    { key: QuoteQuestionsKeys.HasFallenLimbs, type: QuoteQuestionFieldType.Checkbox },
    { key: QuoteQuestionsKeys.HasCutLimbs, type: QuoteQuestionFieldType.Checkbox },
    { key: QuoteQuestionsKeys.HasRocks, type: QuoteQuestionFieldType.Checkbox },
    { key: QuoteQuestionsKeys.HasMulch, type: QuoteQuestionFieldType.Checkbox },
    { key: QuoteQuestionsKeys.HasPine, type: QuoteQuestionFieldType.Checkbox },
    { key: QuoteQuestionsKeys.HasPetWaste, type: QuoteQuestionFieldType.Checkbox },
    { key: QuoteQuestionsKeys.HasCement, type: QuoteQuestionFieldType.Checkbox },
    { key: QuoteQuestionsKeys.HasAcorns, type: QuoteQuestionFieldType.Checkbox },
    { key: QuoteQuestionsKeys.HasPineCones, type: QuoteQuestionFieldType.Checkbox },
    { key: QuoteQuestionsKeys.HasOther, type: QuoteQuestionFieldType.Checkbox },
    {
      key: QuoteQuestionsKeys.OtherDebris,
      type: QuoteQuestionFieldType.TextArea,
      dependsOn: QuoteQuestionsKeys.HasOther,
    },
  ],
};

export const additionalInstructionsQuestion: Question = {
  label: QuoteQuestions.AdditionalInstructions,
  fields: [{ key: QuoteQuestionsKeys.Details, type: QuoteQuestionFieldType.TextArea }],
};
