import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';
import { ReactNode, useCallback } from 'react';
import MaterialIcons from 'react-native-vector-icons/glyphmaps/MaterialCommunityIcons.json';

import { StyledWrapper } from './styles';
import { lsI18NService } from '../../service';

type MaterialIcon = keyof typeof MaterialIcons;

export interface BinaryModalWrapperProps {
  className?: string;
  onConfirm: (e?: React.MouseEvent) => void;
  children: ReactNode;
  description?: string;
  icon: MaterialIcon;
}

export const BinaryModalWrapper = ({ className, onConfirm, children, description, icon }: BinaryModalWrapperProps) => {
  const modal = useModal(ModalTemplate.BINARY_CHOICE);
  const openModal = useCallback(() => {
    modal.show({
      animationType: 'fade',
      description,
      choices: [
        { label: lsI18NService.t('modal.dismiss'), onPress: modal.hide },
        { label: lsI18NService.t('modal.confirm'), onPress: onConfirm },
      ],
      icon,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal, onConfirm]);

  return (
    <StyledWrapper onClick={openModal} data-testid="modal-wrapper" className={className}>
      {children}
    </StyledWrapper>
  );
};
