import { useParams } from 'react-router-dom';
import { useMemo } from 'react';

import { StepType } from '../../enums/schema';
import { useSchema } from '../../hooks/useSchema';
import { VerticalSelection } from '../Cart/VerticalSelection';

export const Onboarding = () => {
  const { stepId } = useParams();
  const { schema } = useSchema();

  const MemoizedStep =
    useMemo(
      () => schema.steps.find(({ id, type }) => type === StepType.Onboarding && id === stepId)?.component,
      [schema.steps, stepId],
    ) || VerticalSelection;

  return <MemoizedStep />;
};
