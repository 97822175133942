import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../..';
import { Nullable } from '@lawnstarter/ls-react-common/types';

type NullableString = Nullable<string>;
export interface PropertyState {
  name: NullableString;
  address1: NullableString;
  address2?: NullableString;
  city: NullableString;
  state: NullableString;
  country: NullableString;
  zip: NullableString;
  number: NullableString;
}

export const initialState: PropertyState = {
  name: localStorage.getItem('property.name'),
  address1: localStorage.getItem('property.address1'),
  address2: localStorage.getItem('property.address2'),
  city: localStorage.getItem('property.city'),
  state: localStorage.getItem('property.state'),
  zip: localStorage.getItem('property.zip'),
  country: localStorage.getItem('property.country'),
  number: localStorage.getItem('property.number'),
};

const slice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    updateProperty(state, { payload }: PayloadAction<Partial<PropertyState>>) {
      payload.name && localStorage.setItem('property.name', payload.name);
      payload.address1 && localStorage.setItem('property.address1', payload.address1);
      payload.address2 && localStorage.setItem('property.address2', payload.address2);
      payload.city && localStorage.setItem('property.city', payload.city);
      payload.state && localStorage.setItem('property.state', payload.state);
      payload.zip && localStorage.setItem('property.zip', payload.zip);
      payload.country && localStorage.setItem('property.country', payload.country);
      payload.number && localStorage.setItem('property.number', payload.number);

      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const { updateProperty } = slice.actions;

export default slice.reducer;

export const selectProperty = (state: RootState) => state.property;
