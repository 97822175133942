import { useMemo } from 'react';
import { isString } from 'lodash';
import { theme as defaultTheme, useAppTheme } from '@lawnstarter/ls-react-common';
import { capitalize } from 'lodash';

export function useCSSBindTheme() {
  const appTheme = useAppTheme();

  // Resolve nested colors in flat colors
  const colors = useMemo(() => {
    const mergedColors = { ...defaultTheme.colors, ...appTheme.colors };
    const flatColors: [string, string][] = [];

    Object.entries(mergedColors).forEach((entry) => {
      if (isString(entry[1])) {
        flatColors.push(entry as [string, string]); // ['primary', '#color']
      } else {
        Object.entries(entry[1]).forEach(
          (nested) => flatColors.push([`${entry[0]}${capitalize(nested[0])}`, nested[1]]), // ['grayPrimary', '#color']
        );
      }
    });

    return flatColors;
  }, [appTheme.colors]);

  // Creates a branded Theme object
  const fullTheme = useMemo(() => {
    return {
      ...defaultTheme,
      ...appTheme,
      colors,
    };
  }, [appTheme, colors]);

  type SpacingKeys = keyof typeof fullTheme.spacing;

  const spacingCssVars = useMemo(
    () => Object.keys(fullTheme.spacing).map((key) => `--${key}: ${fullTheme.spacing[key as SpacingKeys]}px;`),
    [fullTheme.spacing],
  );

  const colorsCssVars = useMemo(
    () => fullTheme.colors.map((color) => `--${color[0]}: ${color[1]};`),
    [fullTheme.colors],
  );

  const cssVars = [...spacingCssVars, ...colorsCssVars].reduce((acc, value) => acc + value, '');

  return { cssVars };
}
