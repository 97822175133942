import tw from 'tailwind-styled-components';

interface RecommendedProps {
  $isRecommended: boolean;
}

interface SelectedProps {
  $isSelected: boolean;
}

export const StyledWrapper = tw.div`my-1`;

export const StyledRecommended = tw.div<SelectedProps>`
  inline-block rounded-t-lg bg-primary text-white py-2 px-4 text-sm font-semibold
  ${(p) => (p.$isSelected ? 'bg-primary' : 'bg-[var(--grayPrimary)]')}
`;

export const StyledButton = tw.button<RecommendedProps & SelectedProps>`
  grid grid-cols-5 rounded-lg overflow-hidden w-full relative
  ${(p) => p.$isSelected && `relative before:content-[' '] before:absolute before:w-full before:h-full before:top-0 before:left-0 before:block before:shadow-border before:rounded-lg`}
  ${(p) => p.$isRecommended && 'rounded-tl-none'}
  ${(p) => p.$isRecommended && p.$isSelected && 'before:rounded-tl-none'}
`;

export const StyledCol1 = tw.div<SelectedProps>`
  flex h-full items-center px-4 py-1 col-span-3 text-left text-lg font-semibold 
  ${(p) => (p.$isSelected ? 'bg-[var(--primaryLight)]' : 'bg-[var(--graySecondary)]')}
`;

export const StyledCol2 = tw.div<SelectedProps>`
  flex flex-col h-full items-center px-4 py-1 col-span-2
  ${(p) => (p.$isSelected ? 'bg-primary' : 'bg-[var(--grayPrimary)]')}
`;

export const StyledPrice = tw.span`
  text-right w-full text-3xl font-bold text-white
`;

export const StyledCaption = tw.span`
  text-right w-full text-xs font-semibold text-white 
`;
