import { StyledDotList, StyledDot } from './styles';

interface NavDotsProps {
  dots: number;
  selectedDot: number;
  className?: string;
}

export const NavDots = (props: NavDotsProps) => {
  return (
    <>
      <StyledDotList className={props.className}>
        {Array(props.dots)
          .fill(0)
          .map((_, index) => (
            <StyledDot key={index} className={index + 1 === props.selectedDot ? 'text-primary' : 'text-gray-300'}>
              &bull;
            </StyledDot>
          ))}
      </StyledDotList>
    </>
  );
};
