import tw from 'tailwind-styled-components';

export const StyledList = tw.ul``;

export const StyledListItem = tw.li`
  pl-2
`;

export const StyledWrapperPhone = tw.div`
  h-20
`;
