export enum QueryString {
  // Metadata
  Name = 'name',
  Phone = 'phone',
  PromoCode = 'deal',
  SegmentId = 'segid',
  Intent = 'intent',
  // Address
  Address = 'address',
  GooglePlaceId = 'placeId',
  // Marketing Data
  UtmSource = 'utm_source',
  UtmMedium = 'utm_medium',
  UtmTerm = 'utm_term',
  UtmContent = 'utm_content',
  UtmCampaign = 'utm_campaign',
  GoogleId = 'gclid',
  FacebookId = 'fbcid',
}

export enum WwwCookies {
  Phone = 'ls.saved_order_module.phone',
  Name = 'ls.saved_order_module.name',
  Address = 'ls.saved_order_module.address',
}

export enum CommonCookies {
  SegmentAnonymousId = 'ajs_anonymous_id',
}

export enum LocalStorage {
  Brand = 'brand',
  Intent = 'intent',
  AppVersion = 'appVersion',
}
