import tw from 'tailwind-styled-components';
import { theme } from '@lawnstarter/ls-react-common';

export const StyledWrapper = tw.div`
  mt-6 rounded-lg border-gray-200 border-2 overflow-hidden mb-8
`;

export const StyledHeader = tw.div`
  flex items-center bg-gray-200 p-2
`;

export const StyledMain = tw.div`
  py-4 px-4
`;

export const titleStyle = {
  color: theme.colors.gray.tertiary,
  marginLeft: theme.sizing.s2,
  width: '100%',
};
