import { setFontWeight, theme } from '@lawnstarter/ls-react-common';
import tw from 'tailwind-styled-components';

export const StyledBody = tw.div`
  flex flex-col items-center md:justify-center flex-1 mt-7 overflow-hidden
`;

export const StyledContent = tw.div`
  text-center mt-6 md:mt-[37px] w-full
`;

export const StyledToast = tw.div`
  absolute md:hidden bottom-4 w-wired
`;

export const spinnerStyle = { marginTop: theme.spacing.s6 };

export const titleStyle = {
  ...setFontWeight('600'),
};
