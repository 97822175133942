import { lsI18NService } from '../service';

interface GraphQLErrorType {
  message: string;
  error: string;
}

export const InvalidPhoneError: GraphQLErrorType = {
  error: 'Invalid phone number',
  message: lsI18NService.t('formErrors.invalidPhoneNumber'),
};

export const EmailAlreadyTakenError: GraphQLErrorType = {
  error: 'The email has already been taken',
  message: lsI18NService.t('formErrors.accountAlreadyExists'),
};

export const InvalidPromoCodeError: GraphQLErrorType = {
  error: 'Promo code is not valid or has expired',
  message: lsI18NService.t('formErrors.invalidPromoCode'),
};

export const InvalidCVCError: GraphQLErrorType = {
  error: "Your card's security code is incorrect",
  message: lsI18NService.t('formErrors.invalidSecurityCode'),
};

export const ExpiredCardError: GraphQLErrorType = {
  error: 'Your card has expired',
  message: lsI18NService.t('formErrors.expiredCard'),
};

export const InvalidCardError: GraphQLErrorType = {
  error: 'Your card was declined',
  message: lsI18NService.t('formErrors.declinedCard'),
};

export const InvalidAddressError: GraphQLErrorType = {
  error: 'Variable "$zip" of non-null type "String!" must not be null',
  message: lsI18NService.t('formErrors.invalidStreetNumber'),
};

// The toast won't be trigger for these errors bcs they are handled on component level (e.g. form validation)
export const GraphQLHandledErrors: GraphQLErrorType['error'][] = [
  EmailAlreadyTakenError.error,
  InvalidPhoneError.error,
  InvalidPromoCodeError.error,
];

// The toast will be triggered with a custom messages for these errors
export const GraphQLCustomErrors: GraphQLErrorType[] = [
  InvalidCVCError,
  InvalidCardError,
  ExpiredCardError,
  InvalidAddressError,
];
