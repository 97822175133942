import { VerticalSchema } from '../types/schema';
import { StepId, StepType, Vertical } from '../enums/schema';
import { VerticalSelection } from '../pages/Cart/VerticalSelection';
import { Policies } from '../enums/policies';
import { Service } from '../enums/service';
import { AccountAndPayment } from '../pages/Cart/AccountAndPayment';
import { ContactInfo } from '../pages/Cart/ContactInfo';
import { ExtraServices } from '../pages/Cart/ExtraServices';
import { Instructions } from '../pages/Cart/Instructions';
import { LawnTreatment } from '../pages/Cart/LawnTreatment';
import { QuoteQuestions } from '../pages/Cart/QuoteQuestions';
import { Scheduling } from '../pages/Cart/Scheduling';
import { Welcome } from '../pages/Cart/Welcome';
import { Titles } from '../enums/titles';
import {
  additionalInstructionsQuestion,
  disposeDebrisQuestion,
  removeDebrisQuestion,
  removeLeavesQuestion,
  whereToActQuestion,
} from '../constants/quoteQuestions';

export const schema: VerticalSchema = {
  vertical: Vertical.LeafRemoval,
  service: Service.LeafRemoval,
  steps: [
    {
      id: StepId.VerticalSelection,
      type: StepType.Cart,
      component: VerticalSelection,
    },
    {
      id: StepId.ContactInfo,
      type: StepType.Cart,
      component: ContactInfo,
      options: {
        calculationScreen: {
          title: Titles.FoundProperty,
        },
      },
    },
    {
      id: StepId.QuoteQuestions,
      type: StepType.Cart,
      component: QuoteQuestions,
      options: {
        questions: [
          whereToActQuestion,
          disposeDebrisQuestion,
          removeLeavesQuestion,
          removeDebrisQuestion,
          additionalInstructionsQuestion,
        ],
      },
    },
    {
      id: StepId.Scheduling,
      type: StepType.Cart,
      component: Scheduling,
    },
    {
      id: StepId.AccountAndPayment,
      type: StepType.Cart,
      component: AccountAndPayment,
      options: {
        tos: [Policies.ThreeCut, Policies.LongGrass],
      },
    },
    {
      id: StepId.Instructions,
      type: StepType.Cart,
      component: Instructions,
    },
    {
      id: StepId.ExtraServices,
      type: StepType.Cart,
      component: ExtraServices,
      services: [Service.BushTrimming, Service.TreeCare, Service.Cleanups, Service.Landscaping, Service.Other],
    },
    {
      id: StepId.LawnTreatment,
      type: StepType.Cart,
      component: LawnTreatment,
    },
    {
      id: StepId.Welcome,
      type: StepType.Cart,
      component: Welcome,
    },
  ],
};
