import { Icon, Text } from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { StyledIcon } from './styles';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';
import { useCallback } from 'react';
import { lsI18NService } from '../../../../../service';
import { Questions } from '../Questions';
import { trackHelpViewed } from '../../../../../service/segment/trackers';
import { Events } from '../../../../../enums/events';
import { useTrackPayload } from '../../../../../hooks/useTrackPayload';

export const ModalContent = () => {
  const { spacing } = useAppTheme();
  return (
    <>
      <Text variant="headlineMedium" style={{ marginBottom: spacing.s6 }}>
        {lsI18NService.t('faq')}
      </Text>
      <Questions />
    </>
  );
};

export const CompactFaq = () => {
  const { colors, spacing } = useAppTheme();
  const modal = useModal(ModalTemplate.GENERIC);
  const { payloadBuilder } = useTrackPayload(Events.HelpViewed);

  const openModal = useCallback(() => {
    modal.show({
      animationType: 'fade',
      content: ModalContent,
      buttonText: lsI18NService.t('ok'),
    });

    const payload = payloadBuilder({ helpContext: 'lawn_treatment' });
    payload && trackHelpViewed(payload);
  }, [modal, payloadBuilder]);

  return (
    <StyledIcon onClick={openModal} data-testid="lawntreatment-faq-mobile" className="md:hidden">
      <Icon name="help-circle-outline" color={colors.primary} size={spacing.s8} />
    </StyledIcon>
  );
};
