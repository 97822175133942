import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardNumberElement } from '@stripe/stripe-js';
import { result } from 'lodash';
import { useCallback } from 'react';

interface useStripeFormProps {
  setPaymentToken: (token: string) => void;
}

export const useStripeForm = ({ setPaymentToken }: useStripeFormProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();

      try {
        const card = elements?.getElement(CardNumberElement) as StripeCardNumberElement;
        const result = await stripe?.createToken(card, { address_country: 'US' });

        if (result?.token) {
          setPaymentToken(result.token.id);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    },
    [elements, stripe, setPaymentToken],
  );

  return { handleSubmit, stripe: result };
};
