import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Events } from '../../enums/events';
import { contactInfoStep } from '../../hooks/useCheckValidState';
import { useTrackPayload } from '../../hooks/useTrackPayload';
import { trackOnAddressChange } from '../../service/segment/trackers';
import { resetCart } from '../../store/modules/cart/slice';
import { selectProspect } from '../../store/modules/prospect/slice';
import { updateApp } from '../../store/modules/app/slice';

export const useAddressButton = () => {
  const dispatch = useDispatch();
  const { payloadBuilder } = useTrackPayload(Events.OnAddressChange);
  const navigate = useNavigate();
  const { promoCode } = useSelector(selectProspect);

  const onAddressChange = useCallback(() => {
    const payload = payloadBuilder();
    payload && trackOnAddressChange(payload);
    dispatch(resetCart());

    dispatch(updateApp({ promoCode }));

    navigate({ pathname: contactInfoStep });
  }, [dispatch, navigate, payloadBuilder, promoCode]);

  return { onAddressChange };
};
