import { useEffect } from 'react';
import { VITE_BRAND } from '../config/env';
import { lsI18NService } from '../service/i18n/i18n';
import { Brand } from '@lawnstarter/ls-react-common/enums';
import { METADATA } from '../constants/metadata';

export function useDocumentTitle(translatedKey: string = 'default') {
  const { displayName } = METADATA[VITE_BRAND as Brand];

  useEffect(() => {
    const translatedTitle = lsI18NService.t(`document.title.${translatedKey}`, { brand: displayName });
    document.title = translatedTitle || displayName;
  }, [displayName, translatedKey]);
}
