import { Vertical } from '../enums/schema';
import { LocalStorage } from '../enums/storage';

export const isVertical = (vertical: string): vertical is Vertical =>
  Object.values(Vertical).includes(vertical as Vertical);

export const getVertical = (): Vertical => {
  const localVertical = localStorage.getItem(LocalStorage.Intent) as string;
  const vertical = isVertical(localVertical) ? (localVertical as Vertical) : Vertical.Mowing;

  return vertical;
};
