import { setFontWeight, theme } from '@lawnstarter/ls-react-common';
import tw from 'tailwind-styled-components';

export const StyledCart = tw.div`
  mb-3
`;

export const StyledContainer = tw.div`
  flex justify-between
`;

export const StyledItem = tw.div`
  flex justify-between mb-3
`;

export const StyledDetail = tw.div`
  md:mt-9
`;

export const styleTitle = {
  ...setFontWeight('700'),
};

export const styleDescription = {
  color: theme.colors.gray.tertiary,
};
