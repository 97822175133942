import { Text } from '@lawnstarter/ls-react-common/atoms';
import { lsI18NService } from '../../../../../service';
import './style.css';

export const TreatmentTable = () => {
  return (
    <>
      <Text variant="titleMedium">{lsI18NService.t('lawnTreatment.infoTable.planDetailsHeadline')}</Text>
      <table className="w-full my-2">
        <thead>
          <tr>
            <th>{lsI18NService.t('lawnTreatment.infoTable.lawnProblemTitle')}</th>
            <th>{lsI18NService.t('lawnTreatment.infoTable.treatmentTitle')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Text variant="bodyMedium">{lsI18NService.t('lawnTreatment.infoTable.weedRemoval')}</Text>
            </td>
            <td>
              <Text variant="bodyMedium">{lsI18NService.t('lawnTreatment.infoTable.preEmergent')}</Text>
              <Text variant="bodyMedium">{lsI18NService.t('lawnTreatment.infoTable.lateSpring')}</Text>
              <Text variant="bodyMedium">{lsI18NService.t('lawnTreatment.infoTable.postEmergent')}</Text>
              <Text variant="bodyMedium">{lsI18NService.t('lawnTreatment.infoTable.springSummerFall')}</Text>
              <Text variant="bodyMedium">{lsI18NService.t('lawnTreatment.infoTable.weedControl')}</Text>
              <Text variant="bodyMedium">{lsI18NService.t('lawnTreatment.infoTable.yearRound')}</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text variant="bodyMedium">{lsI18NService.t('lawnTreatment.infoTable.lawnHealth')}</Text>
            </td>
            <td>
              <Text variant="bodyMedium">{lsI18NService.t('lawnTreatment.infoTable.fertilization')}</Text>
              <Text variant="bodyMedium">{lsI18NService.t('lawnTreatment.infoTable.yearRound')}</Text>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
