import { VerticalSchema } from '../types/schema';
import { StepId, StepType, Vertical } from '../enums/schema';
import { VerticalSelection } from '../pages/Cart/VerticalSelection';
import { ContactInfo } from '../pages/Cart/ContactInfo';
import { Scheduling } from '../pages/Cart/Scheduling';
import { AccountAndPayment } from '../pages/Cart/AccountAndPayment';
import { Welcome } from '../pages/Cart/Welcome';
import { ExtraServices } from '../pages/Cart/ExtraServices';
import { LawnTreatment } from '../pages/Cart/LawnTreatment';
import { Service } from '../enums/service';
import { Policies } from '../enums/policies';

export const schema: VerticalSchema = {
  vertical: Vertical.Mowing,
  service: Service.Mowing,
  steps: [
    {
      id: StepId.VerticalSelection,
      type: StepType.Cart,
      component: VerticalSelection,
    },
    {
      id: StepId.ContactInfo,
      type: StepType.Cart,
      component: ContactInfo,
    },
    {
      id: StepId.Scheduling,
      type: StepType.Cart,
      component: Scheduling,
      options: {
        recommendedCycle: 7,
      },
    },
    {
      id: StepId.AccountAndPayment,
      type: StepType.Cart,
      component: AccountAndPayment,
      options: {
        tos: [Policies.ThreeCut, Policies.LongGrass, Policies.FortyEight],
      },
    },
    {
      id: StepId.ExtraServices,
      type: StepType.Cart,
      component: ExtraServices,
      services: [
        Service.BushTrimming,
        Service.TreeCare,
        Service.Cleanups,
        Service.LeafRemoval,
        Service.Landscaping,
        Service.Other,
      ],
    },
    {
      id: StepId.LawnTreatment,
      type: StepType.Cart,
      component: LawnTreatment,
    },
    {
      id: StepId.Welcome,
      type: StepType.Cart,
      component: Welcome,
    },
  ],
};
