import tw from 'tailwind-styled-components';

export const StyledTextInputWrapper = tw.div`
  md:pb-8 pb-4
`;

export const styleMobileToast = 'md:hidden mt-6 mb-2';
export const styleDesktopToast = 'hidden md:block mb-6';

export const StyledFullNameWrapper = tw.div`
  flex gap-2
`;

export const StyledHeadlineWrapper = tw.div`
  flex md:flex-col flex-row
`;
