import { lsI18NService } from '../../../service';
import { Step } from '../../../layout/Step';
import { Button } from '@lawnstarter/ls-react-common/atoms';
import { useVerticalSelection } from './controller';
import { StepTitle } from '../../../components/StepTitle';
import { StepId, Vertical } from '../../../enums/schema';
import { SelectOption } from '@lawnstarter/ls-react-common/molecules';
import { useFlags } from 'flagsmith/react';
import { Flag } from '../../../enums/flags';
import { useMemo } from 'react';

export const VerticalSelection = () => {
  const { onContinue, onVerticalChange, vertical } = useVerticalSelection();
  const flags = useFlags([Flag.LeafRemoval]);

  const verticals = useMemo(() => {
    const isEnabled = (v: Vertical) => flags[Flag.LeafRemoval].enabled || v !== Vertical.LeafRemoval;
    const verticals = Object.values(Vertical).filter(isEnabled);

    return verticals.map((vertical, key) => ({
      key,
      entity: vertical,
      label: lsI18NService.t(`${vertical}.title`),
    }));
  }, [flags]);

  return (
    <Step>
      <Step.Body>
        <Step.Content>
          <StepTitle stepId={StepId.VerticalSelection} />
        </Step.Content>

        <Step.Form>
          <SelectOption onEntitySelect={onVerticalChange} options={verticals} textAlignment={'center'} />
          <Step.Footer>
            <Step.Action>
              <Button onPress={onContinue} mode="contained" trackID="vertical-selection-cta" disabled={!vertical}>
                {lsI18NService.t('continue')}
              </Button>
            </Step.Action>
          </Step.Footer>
        </Step.Form>
      </Step.Body>
    </Step>
  );
};
