import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon, Text } from '@lawnstarter/ls-react-common/atoms';
import { Brand } from '@lawnstarter/ls-react-common/enums';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { VITE_BRAND } from '../../config/env';
import { METADATA } from '../../constants/metadata';
import { useDynamicSvgImport } from '../../hooks/useDynamicSvgImport';
import { HeaderVariants, selectApp } from '../../store/modules/app/slice';
import { StyledHeader, StyledWrapper, StyledWrapperPhone, styleLabel } from './styles';
import { BinaryModalWrapper } from '../../components/BinaryModalWrapper';
import { StyledStroke } from '../../common/styles';
import { useTrackPayload } from '../../hooks/useTrackPayload';
import { Events } from '../../enums/events';
import { trackCallClicked } from '../../service/segment/trackers';
import { CustomerSignupContext } from '../../types/track';
import { lsI18NService } from '../../service';
import { selectProperty } from '../../store/modules/property/slice';
import { useAddressButton } from './controller';

export default function Header() {
  const { Svg: Logo } = useDynamicSvgImport('logo');
  const { shouldShowHeader, headerRightSlot, shouldShowAddress } = useSelector(selectApp);
  const { colors, sizing } = useAppTheme();
  const { phone } = METADATA[VITE_BRAND as Brand];
  const { payloadBuilder } = useTrackPayload(Events.CallClicked);
  const { onAddressChange } = useAddressButton();
  const property = useSelector(selectProperty);
  const [street] = useMemo(() => (property.address1 ? property.address1.split(',') : []), [property.address1]);

  const onCall = () => {
    const payload = payloadBuilder({ signupContext: CustomerSignupContext.HEADER });
    payload && trackCallClicked(payload);

    window.location.assign(`tel:${phone.raw}`);
  };

  const RightSlot = () => {
    switch (headerRightSlot) {
      case HeaderVariants.DetailedBadgePhone:
        return (
          <StyledWrapperPhone onClick={onCall}>
            <Icon color={colors.primary} name="phone" size={sizing.s6} testID="detailed-badge-phone" />
          </StyledWrapperPhone>
        );
      default:
        return <></>;
    }
  };

  return (
    shouldShowHeader && (
      <StyledWrapper>
        <StyledHeader>
          <Logo />
          <RightSlot />
          {shouldShowAddress && (
            <BinaryModalWrapper
              description={lsI18NService.t('addressButton.description')}
              className="hidden md:block"
              onConfirm={onAddressChange}
              icon="home-alert-outline"
            >
              <Text variant="titleSmall" style={styleLabel}>
                <Icon color="home" name="home-outline" size={sizing.s5} />
                &nbsp;{street}
              </Text>
            </BinaryModalWrapper>
          )}
        </StyledHeader>
        {shouldShowAddress && (
          <BinaryModalWrapper
            description={lsI18NService.t('addressButton.description')}
            className="-mt-4 mb-6 md:hidden"
            onConfirm={onAddressChange}
            icon="home-alert-outline"
          >
            <Text variant="titleSmall" style={styleLabel}>
              <Icon color="home" name="home-outline" size={sizing.s5} />
              &nbsp;{street}
            </Text>
          </BinaryModalWrapper>
        )}
        <StyledStroke className="mb-4 md:hidden" />
      </StyledWrapper>
    )
  );
}
