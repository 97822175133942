import { setFontWeight } from '@lawnstarter/ls-react-common';
import { Text } from '@lawnstarter/ls-react-common/atoms';
import { CheckboxOptions } from '@lawnstarter/ls-react-common/molecules';
import { Control, Controller } from 'react-hook-form';

import { VITE_WWW_TERMS } from '../../../../../config/env';
import { lsI18NService } from '../../../../../service';
import { StyledLink } from '../../../ContactInfo/components/Form/components/styles';
import { StyledCheckBox, StyledTermContainer } from './styles';

interface PropsCheckbox {
  shouldHighlightError: boolean;
  form: {
    control: Control<
      {
        firstName: unknown;
        lastName: unknown;
        email: string;
        termsCheckbox: string;
      },
      string
    >;
  };
  checkboxRef: React.RefObject<HTMLFormElement>;
}

export const TosCheckbox = ({ checkboxRef, form, shouldHighlightError }: PropsCheckbox) => {
  return (
    <StyledCheckBox ref={checkboxRef}>
      <Controller
        control={form.control}
        name="termsCheckbox"
        rules={{ required: true }}
        render={() => (
          <CheckboxOptions
            options={[{ label: '', value: 'termsCheckbox' }]}
            control={form.control}
            style={{ checkboxContainer: { margin: 0, padding: 0 } }}
            shouldHighlightError={shouldHighlightError}
          />
        )}
      />
      <StyledTermContainer>
        <Text variant="labelMedium" style={{ ...setFontWeight('400') }}>
          {lsI18NService.t('accountAndPayment.checkbox')}
        </Text>
        <StyledLink href={VITE_WWW_TERMS} target="_blank" rel="noreferrer">
          {`${lsI18NService.t(`accountAndPayment.terms`)}`}
        </StyledLink>
      </StyledTermContainer>
    </StyledCheckBox>
  );
};
