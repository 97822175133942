import { setFontWeight, useAppTheme } from '@lawnstarter/ls-react-common';
import { Text } from '@lawnstarter/ls-react-common/atoms';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useDynamicSvgImport } from '../../hooks/useDynamicSvgImport';
import { updateApp } from '../../store/modules/app/slice';
import { StyledBody, StyledSvg } from './styles';

type DinamicImport = { name: string; isBranded: boolean };

export type ErrorScreenProps = {
  image: DinamicImport;
  title: string;
  label: string;
};

export const ErrorScreen = (props: ErrorScreenProps) => {
  const { image, title, label } = props;
  const { Svg } = useDynamicSvgImport(image.name, false);
  const dispatch = useDispatch();
  const { sizing } = useAppTheme();

  useEffect(() => {
    dispatch(updateApp({ shouldShowHeader: false }));
    return () => {
      dispatch(updateApp({ shouldShowHeader: true }));
    };
  }, [dispatch]);

  return (
    <StyledBody data-testid="error-screen">
      <StyledSvg>
        <Svg className="justify-self-center" />
        <Text
          variant="headlineSmall"
          style={{ ...setFontWeight('600'), marginTop: sizing.s8, marginBottom: sizing.s8, textAlign: 'center' }}
        >
          {title}
        </Text>
        <Text style={{ textAlign: 'center' }}>{label}</Text>
      </StyledSvg>
    </StyledBody>
  );
};
