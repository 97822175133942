import { lsI18NService } from '../../../../../service';
import { StyledWrapper, StyledPrice, StyledTimes } from './styles';

interface PriceSummaryProps {
  price: string;
  times: string;
}

export const PriceSummary = ({ price, times }: PriceSummaryProps) => {
  return (
    <StyledWrapper>
      <StyledPrice data-testid="price">{lsI18NService.t('lawnTreatment.priceHeadline', { price })}</StyledPrice>
      <StyledTimes>{lsI18NService.t('lawnTreatment.timesAYear', { times })}</StyledTimes>
    </StyledWrapper>
  );
};
