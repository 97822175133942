import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../..';
import { Nullable } from '@lawnstarter/ls-react-common/types';

type NullableString = Nullable<string>;
export interface ContactState {
  id: NullableString;
  name: NullableString;
  phone: NullableString;
  email: NullableString;
}

export const initialState: ContactState = {
  id: localStorage.getItem('contact.id'),
  name: localStorage.getItem('contact.name'),
  phone: localStorage.getItem('contact.phone'),
  email: localStorage.getItem('contact.email'),
};

const slice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    updateContact(state, { payload }: PayloadAction<Partial<ContactState>>) {
      payload.id && localStorage.setItem('contact.id', payload.id);
      payload.name && localStorage.setItem('contact.name', payload.name);
      payload.phone && localStorage.setItem('contact.phone', payload.phone);
      payload.email && localStorage.setItem('contact.email', payload.email);

      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const { updateContact } = slice.actions;

export default slice.reducer;

export const selectContact = (state: RootState) => state.contact;
