import { StepId } from '../../../enums/schema';
import { useStepNavigation } from '../../../hooks/useStepNavigation';

const stepId = StepId.Instructions;

export const Instructions = () => {
  const { currentStep, nextStep, vertical } = useStepNavigation({ stepId });

  return <>{JSON.stringify({ currentStep, nextStep, vertical })}</>;
};
