import tw from 'tailwind-styled-components';

export const StyledWrapper = tw.div`
  min-h-56
`;

export const StyledActionWrapper = tw.div`
  gap-2 mt-16
`;

export const StyledClose = tw.div`
  absolute mt-2 right-0 top-0 z-10 p-3
`;
