import tw from 'tailwind-styled-components';

export const StyledScroller = tw.div`
  w-full overflow-x-auto lg:overflow-visible snap-x snap-mandatory scroll-smooth mt-8 mb-4
`;

export const StyledScrollable = tw.div`
  w-[300%] lg:w-full flex-nowrap gap-2 grid grid-cols-3 lg:grid-cols-2
`;

export const StyledItem = tw.div`
  snap-center snap-always relative
`;

export const StyledItemDescription = tw.div`
  absolute bottom-7 z-50 text-white text-center w-full
`;

export const descriptionStyle = { textAlign: 'center', display: 'block', width: '100%' };
