import { useEffect } from 'react';
import { VITE_BRAND } from '../config/env';
import { Brand } from '@lawnstarter/ls-react-common/enums';
import { METADATA } from '../constants/metadata';

export function useFavicon() {
  const { favicon } = METADATA[VITE_BRAND as Brand];

  useEffect(() => {
    let link = document.querySelector<HTMLLinkElement>("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    link.href = favicon;
  }, [favicon]);
}
