import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon, Text } from '@lawnstarter/ls-react-common/atoms';
import { Brand } from '@lawnstarter/ls-react-common/enums';

import { METADATA } from '../../../../../../constants/metadata';
import { VITE_BRAND, VITE_WWW_PRIVACY, VITE_WWW_TERMS } from '../../../../../../config/env';
import { lsI18NService } from '../../../../../../service';
import { StyledInfoBox, StyledLink, StyledWrapper } from './styles';

export const RichText = () => {
  const { colors, sizing } = useAppTheme();
  const { displayName } = METADATA[VITE_BRAND as Brand];

  return (
    <StyledWrapper>
      <Text
        variant="bodySmall"
        style={{ color: colors.gray.primary, textAlign: 'center' }}
        testID="consent-declaration"
      >
        {lsI18NService.t('contactInfo.consentDeclaration.description', { brand: displayName })}
        <StyledLink href={VITE_WWW_TERMS} target="_blank" rel="noreferrer">
          {`${lsI18NService.t(`contactInfo.consentDeclaration.termsOfService`)}`}
        </StyledLink>
        {` ${lsI18NService.t('and')}`}
        <StyledLink href={VITE_WWW_PRIVACY} target="_blank" rel="noreferrer">
          {`${lsI18NService.t(`contactInfo.consentDeclaration.privacyPolicy`)}`}
        </StyledLink>
      </Text>
      <StyledInfoBox>
        <Icon name="lock-outline" size={sizing.s6} />
        <Text style={{ color: colors.gray.tertiary, textAlign: 'center', verticalAlign: 1 }}>
          {lsI18NService.t('contactInfo.yourInformation')}
        </Text>
      </StyledInfoBox>
    </StyledWrapper>
  );
};
