import { Toast } from '@lawnstarter/ls-react-common/atoms';
import React from 'react';
import { ToastWrapper } from './styles';

interface ErrorToastProps {
  description: string | React.ReactNode;
  className?: string;
  action?: {
    label: string;
    onPress: () => void;
  };
  msToHide?: number;
}

export const ErrorToast = ({ action, description, className, msToHide = 0 }: ErrorToastProps) => {
  return (
    <ToastWrapper className={className}>
      <Toast
        icon="information-outline"
        variant="mandatory"
        description={description}
        msToHide={msToHide}
        action={action}
      />
    </ToastWrapper>
  );
};
