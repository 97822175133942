import { Text } from '@lawnstarter/ls-react-common/atoms';
import { styleDescription, StyledItem } from '../../styles';

interface PriceDetailProps {
  label: string;
  price: string;
}

export const PriceDetail = ({ label, price }: PriceDetailProps) => (
  <StyledItem>
    <Text style={styleDescription} variant="bodySmall">
      {label}
    </Text>
    <Text style={styleDescription} variant="bodySmall">
      {price}
    </Text>
  </StyledItem>
);
