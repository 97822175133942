import tw from 'tailwind-styled-components';
import { theme } from '@lawnstarter/ls-react-common';

export const StyledContent = tw.span`
  mt-2 ml-8
`;

export const accordionStyle = {
  titleText: {
    flex: 1,
    width: 'auto',
    paddingTop: theme.spacing.s3,
  },
  wrapperTitle: {
    flexDirection: 'row-reverse',
  },
};
