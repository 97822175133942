import { FormEvent, useState } from 'react';
import { Text } from '@lawnstarter/ls-react-common/atoms';

import { lsI18NService } from '../../../../../service';

import { useStripeForm } from './controller';
import {
  StyledCardCvcElement,
  StyledCardDetail,
  StyledCardDetailsWrapper,
  StyledCardExpiryElement,
  StyledCardNumberElement,
  StyledCardWrapper,
  StyledErrorMessage,
  StyledForm,
  Styledlabel,
  elementStyles,
  eyebrowStyle,
} from './styles';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';

interface StripeFormProps {
  stripeFormRef: React.RefObject<HTMLFormElement>;
  setPaymentToken: (token: string) => void;
  setIsStripeFormValid: (valid: boolean) => void;
}

export const StripeForm = ({ stripeFormRef, setPaymentToken, setIsStripeFormValid }: StripeFormProps) => {
  const { handleSubmit } = useStripeForm({ setPaymentToken });
  const [isCardNumberOnFocus, setIsCardNumberOnFocus] = useState(false);
  const [isCardCvcOnFocus, setIsCardCvcOnFocus] = useState(false);
  const [isCardExpiryOnFocus, setIsCardExpiryOnFocus] = useState(false);

  const [isCardNumberValidated, setIsCardNumberValidated] = useState<null | boolean>(null);
  const [isCardCvcValidated, setIsCardCvcValidated] = useState<null | boolean>(null);
  const [isCardExpiryValidated, setIsCardExpiryValidated] = useState<null | boolean>(null);

  const validateForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const validations = [
      { validated: isCardNumberValidated, setter: setIsCardNumberValidated },
      { validated: isCardCvcValidated, setter: setIsCardCvcValidated },
      { validated: isCardExpiryValidated, setter: setIsCardExpiryValidated },
    ];

    validations.forEach(({ validated, setter }) => {
      if (validated === null) {
        setter(false);
      }
    });

    const isFormValid = validations.every(({ validated }) => validated === true);

    if (!isFormValid) {
      return;
    }

    setIsStripeFormValid(true);
    handleSubmit(event);
  };

  return (
    <StyledForm onSubmit={validateForm} ref={stripeFormRef}>
      <StyledCardWrapper data-testid="bar">
        <Styledlabel htmlFor="card-number">{lsI18NService.t('accountAndPayment.cardNumberInput.label')}*</Styledlabel>
        <StyledCardNumberElement
          options={{ showIcon: true, style: elementStyles }}
          onFocus={() => {
            setIsCardNumberOnFocus(true);
            isCardNumberValidated === false && setIsCardNumberValidated(null);
          }}
          onBlur={() => setIsCardNumberOnFocus(false)}
          onChange={(e: StripeCardNumberElementChangeEvent) => setIsCardNumberValidated(e.complete)}
          className={`${isCardNumberOnFocus ? 'shadow-input border-success' : ''} ${isCardNumberValidated === false ? 'border-red-500' : ''}`}
          id="card-number"
        />
        {isCardNumberValidated === false && (
          <StyledErrorMessage>{lsI18NService.t('accountAndPayment.cardNumberInput.error')}</StyledErrorMessage>
        )}
      </StyledCardWrapper>
      <StyledCardDetailsWrapper>
        <StyledCardDetail>
          <Styledlabel htmlFor="card-expiry">{lsI18NService.t('accountAndPayment.cardExpiryInput.label')}*</Styledlabel>
          <StyledCardExpiryElement
            options={{ placeholder: '02/25', style: elementStyles }}
            onFocus={() => {
              setIsCardExpiryOnFocus(true);
              isCardExpiryValidated === false && setIsCardExpiryValidated(null);
            }}
            onBlur={() => setIsCardExpiryOnFocus(false)}
            onChange={(e: StripeCardExpiryElementChangeEvent) => setIsCardExpiryValidated(e.complete)}
            className={`${isCardExpiryOnFocus ? 'shadow-input border-success' : ''} ${isCardExpiryValidated === false ? 'border-red-500' : ''}`}
            id="card-expiry"
          />
          {isCardExpiryValidated === false && (
            <StyledErrorMessage>{lsI18NService.t('accountAndPayment.cardExpiryInput.error')}</StyledErrorMessage>
          )}
        </StyledCardDetail>
        <StyledCardDetail>
          <Styledlabel htmlFor="card-cvc">{lsI18NService.t('accountAndPayment.cardCvcInput.label')}*</Styledlabel>
          <StyledCardCvcElement
            options={{ placeholder: '323', style: elementStyles }}
            onFocus={() => {
              setIsCardCvcOnFocus(true);
              isCardCvcValidated === false && setIsCardCvcValidated(null);
            }}
            onBlur={() => setIsCardCvcOnFocus(false)}
            onChange={(e: StripeCardCvcElementChangeEvent) => setIsCardCvcValidated(e.complete)}
            className={`${isCardCvcOnFocus ? 'shadow-input border-success' : ''} ${isCardCvcValidated === false ? 'border-red-500' : ''}`}
            id="cvc"
          />
          {isCardCvcValidated === false && (
            <StyledErrorMessage>{lsI18NService.t('accountAndPayment.cardCvcInput.error')}</StyledErrorMessage>
          )}
        </StyledCardDetail>
      </StyledCardDetailsWrapper>
      <Text variant="bodySmall" style={eyebrowStyle}>
        {lsI18NService.t('accountAndPayment.eyebrow')}
      </Text>
    </StyledForm>
  );
};
