import tw from 'tailwind-styled-components';
import { theme } from '@lawnstarter/ls-react-common';

export const StyledWrapperBanner = tw.div`
  hidden lg:block max-w-[550px]
`;

export const StyledFieldWrapper = tw.form`
  mb-2 mt-6
`;

export const StyledWrapperLink = tw.a`
  underline text-[var(--bluePrimary)] text-center w-full block text-base font-normal
`;

export const PromoCodeSection = tw.div`
  w-full mt-6 lg:mb-8
`;

export const PromoCodeChange = tw.div`
  flex justify-between items-center
`;

export const StyledLink = tw.span`
  underline text-[var(--bluePrimary)] text-sm font-inter cursor-pointer
`;

export const StyledToast = tw.div`
  fixed bottom-4 w-[98%] z-[100] inset-x-0 mx-auto pointer-events-none
`;

export const styleDate = { paddingBottom: theme.sizing.s2, display: 'block' };
