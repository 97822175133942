import { setFontWeight } from '@lawnstarter/ls-react-common';
import tw from 'tailwind-styled-components';

export const StyledContainer = tw.div`
  flex flex-col items-center justify-center md:justify-normal md:mt-24 flex-1 overflow-hidden
`;

export const StyledContent = tw.div`
  text-center mt-6 w-72
`;

export const StyledSpan = tw.span`
  text-[var(--primary)]
`;

export const titleStyle = {
  ...setFontWeight('600'),
};
