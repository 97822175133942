import { lsI18NService } from '../../service';
import {
  StyledCol1,
  StyledCol2,
  StyledButton,
  StyledRecommended,
  StyledWrapper,
  StyledCaption,
  StyledPrice,
} from './styles';

interface SelectorProps {
  title: string;
  caption: string;
  price: string;
  isRecommended?: boolean;
  isSelected?: boolean;
  testID?: string;
  onClick: () => void;
}

export const Selector = (props: SelectorProps) => {
  const { title, caption, price, testID, isRecommended = false, isSelected = false, onClick } = props;

  return (
    <StyledWrapper data-testid={testID} onClick={onClick}>
      {isRecommended && (
        <StyledRecommended data-testid={`${testID}-recommended`} $isSelected={isSelected}>
          {lsI18NService.t('recommended')}
        </StyledRecommended>
      )}
      <StyledButton $isRecommended={isRecommended} $isSelected={isSelected}>
        <StyledCol1 $isSelected={isSelected} data-testid={`${testID}-col1`}>
          {title}
        </StyledCol1>
        <StyledCol2 $isSelected={isSelected} data-testid={`${testID}-col2`}>
          <StyledPrice>{price}</StyledPrice>
          <StyledCaption>{caption}</StyledCaption>
        </StyledCol2>
      </StyledButton>
    </StyledWrapper>
  );
};
