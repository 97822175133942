import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { PromoCodeModal } from '../components/PromoCodeModal';
import { useSelector } from 'react-redux';
import { selectProspect } from '../store/modules/prospect/slice';
import { useLazyGetPromoCodeQuery } from '../store/modules/prospect/api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PromoCode } from '../types/graphql';
import { isString } from 'lodash';
import { lsI18NService } from '../service';
import { centsToDollar } from '../helpers/currency';

export const usePromoCode = () => {
  const modal = useModal(PromoCodeModal);
  const prospect = useSelector(selectProspect);
  const [getPromoCodeQuery, { isSuccess, data }] = useLazyGetPromoCodeQuery();
  const [promocode, setPromocode] = useState<PromoCode | null>(data?.getPromoCode || null);

  const openModal = useCallback(
    ({ setShowToast }: { setShowToast: (status: boolean) => void }) => {
      modal.show({ setShowToast, hide: modal.hide });
    },
    [modal],
  );

  const getPromoCode = useCallback(() => {
    isString(prospect.promoCode) && getPromoCodeQuery({ id: prospect.promoCode });
  }, [getPromoCodeQuery, prospect.promoCode]);

  const title = useMemo(
    () =>
      lsI18NService.t(`promoCode.title.${promocode?.type}`, {
        amount: centsToDollar(promocode?.amount, 0),
        service: lsI18NService.t(`${promocode?.service}.service`),
      }),
    [promocode],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getPromoCode(), []);

  useEffect(() => getPromoCode(), [getPromoCode, prospect.promoCode]);

  useEffect(() => setPromocode(data?.getPromoCode || null), [data?.getPromoCode, isSuccess]);

  return {
    promocode,
    title,
    openModal,
  };
};
