import { DetailedBadge as DetailedBadgeCommon } from '@lawnstarter/ls-react-common/molecules';
import { lsI18NService } from '../../service';
import { StyledDetailedBadge, styleDetailedBadge } from './styles';
import { PhoneDetailedBadgeTypes } from './types';
import { Brand } from '@lawnstarter/ls-react-common/enums';
import { VITE_BRAND } from '../../config/env';
import { METADATA } from '../../constants/metadata';
import { useTrackPayload } from '../../hooks/useTrackPayload';
import { Events } from '../../enums/events';
import { trackCallClicked } from '../../service/segment/trackers';
import { CustomerSignupContext } from '../../types/track';

export const PhoneDetailedBadge = ({ trackID, testID, className }: PhoneDetailedBadgeTypes) => {
  const { phone } = METADATA[VITE_BRAND as Brand];
  const { payloadBuilder } = useTrackPayload(Events.CallClicked);

  const onCall = () => {
    const payload = payloadBuilder({ signupContext: CustomerSignupContext.FAQ });
    payload && trackCallClicked(payload);

    window.location.assign(`tel:${phone.raw}`);
  };

  return (
    <StyledDetailedBadge className={className}>
      <DetailedBadgeCommon
        label={phone.formatted}
        header={lsI18NService.t('contactInfo.callToSignUp')}
        trackID={trackID}
        testID={testID}
        icon="phone"
        style={styleDetailedBadge}
        onPress={onCall}
      />
    </StyledDetailedBadge>
  );
};
