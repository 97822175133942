import rosetta from 'rosetta';

import EN from '../../i18n/en-us.json';

export enum Locales {
  En = 'en',
}

export const locales: Record<Locales, unknown> = {
  [Locales.En]: EN,
};

export const I18NService = (locale: string) => {
  const i18n = rosetta(locales);
  const isLocaleSupported = i18n.table(locale) !== undefined;
  const targetLocale = isLocaleSupported ? locale : Locales.En;

  i18n.locale(targetLocale);

  return { ...i18n, targetLocale };
};

const { language } = new Intl.Locale(navigator.language);

export const lsI18NService = I18NService(language);
