import { Route, Routes, useLocation } from 'react-router-dom';

import { Cart, Onboarding } from '../../pages';
import { AnimatePresence } from 'framer-motion';
import { ContactInfo } from '../../pages/Cart/ContactInfo';
import { StepId, StepType } from '../../enums/schema';
import { useEffect } from 'react';

export default function Main() {
  const location = useLocation();

  useEffect(() => void window.scrollTo(0, 0), [location]);

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path={`${StepType.Cart}/${StepId.ContactInfo}`} element={<ContactInfo />} />
        <Route path="cart/:hashId/:stepId" element={<Cart />} />
        <Route path="onboarding/:hashId/:stepId" element={<Onboarding />} />
        <Route path="*" element={<Cart />} />
      </Routes>
    </AnimatePresence>
  );
}
