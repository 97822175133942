import { useEffect, useRef, useState } from 'react';
import { VITE_TURNSTILE_KEY } from '../../config/env';
import { StyledWrapper } from './styles';
import { TurnstileAPI, TurnstileProps } from './types';
import { updateApp } from '../../store/modules/app/slice';
import { useDispatch } from 'react-redux';

export const Turnstile = ({ onRender, onVerify, hideOnVerified = false }: TurnstileProps) => {
  const turnstileRef = useRef<HTMLDivElement>(null);
  const [verified, setVerified] = useState(false);
  const dispatch = useDispatch();
  const turnstile = (window as typeof window & { turnstile: TurnstileAPI }).turnstile;

  useEffect(() => {
    const loadTurnstile = () => {
      if (turnstile && turnstileRef.current) {
        const widgetId = turnstile.render(turnstileRef.current, {
          sitekey: VITE_TURNSTILE_KEY,
          callback: (token: string) => {
            setVerified(true);
            dispatch(updateApp({ turnstileToken: token }));
            onVerify(token);
          },
        });

        onRender && onRender();

        return widgetId;
      }
    };

    const turnstileWidgetId = loadTurnstile();

    return () => {
      if (turnstileWidgetId) {
        turnstile?.remove(turnstileWidgetId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, turnstile]);

  return (
    <>
      {(!verified || !hideOnVerified) && (
        <StyledWrapper>
          <div data-testid="turnstile" ref={turnstileRef}></div>
        </StyledWrapper>
      )}
    </>
  );
};
