import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectProspect } from '../../../store/modules/prospect/slice';
import { CartItem } from '../../../store/modules/cart/slice';
import { Service } from '../../../enums/service';

export const useLawnTreatment = () => {
  const prospect = useSelector(selectProspect);

  // Extract LTR from the estimated services
  const lawnTreatment: CartItem[] = useMemo(() => {
    const service = prospect.services?.find((service) => service.type === Service.Fertilization);
    return service?.estimates?.length
      ? [{ type: service.type, estimateId: String(service.estimates[0].id), amount: service.estimates[0].amount }]
      : [];
  }, [prospect.services]);

  const defaultValues: Record<string, boolean> = { [Service.Fertilization]: false };

  return { defaultValues, lawnTreatment };
};
