import _ from 'lodash';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon, Text } from '@lawnstarter/ls-react-common/atoms';
import { Brand } from '@lawnstarter/ls-react-common/enums';

import { LoadingScreen } from '../../LoadingScreen';
import { lsI18NService } from '../../../service';
import { VITE_BRAND, VITE_LEGACY_MY_PORTAL } from '../../../config/env';
import { METADATA } from '../../../constants/metadata';
import { StyledLoadingContentWrapper, TextWrapper } from './styles';
import { useAttachServicesToExistingOrderMutation } from '../../../store/modules/prospect/api';
import { selectServices } from '../../../store/modules/cart/slice';

declare global {
  interface Window {
    isLSCustAppWebView: boolean;
    ReactNativeWebView: {
      postMessage: (payload: string) => void;
    };
  }
}

export const STATUS_LEGACY_SIGNUP_COMPLETE = 'signupComplete';
export const Welcome = () => {
  const { sizing, colors } = useAppTheme();
  const { hashId: prospectId } = useParams();
  const { displayName } = METADATA[VITE_BRAND as Brand];
  const { items } = useSelector(selectServices);
  const [attachServicesToExistingOrder, { isSuccess }] = useAttachServicesToExistingOrderMutation();

  useEffect(() => {
    const serviceEstimates = items.map(({ estimateId }) => ({ id: estimateId }));
    serviceEstimates && attachServicesToExistingOrder({ prospectId, serviceEstimates });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const authToken = sessionStorage.getItem('authToken');
    if (!authToken || !isSuccess) {
      return;
    }

    if (window.isLSCustAppWebView) {
      const payload = JSON.stringify({ authtoken: authToken, status: STATUS_LEGACY_SIGNUP_COMPLETE });
      _.get(window, 'ReactNativeWebView.postMessage')
        ? window.ReactNativeWebView.postMessage(payload)
        : window.postMessage(payload);
    }

    window.location.replace(`${VITE_LEGACY_MY_PORTAL}?auth=${authToken}`);
  }, [isSuccess]);

  return (
    <LoadingScreen
      image={{ name: 'celebration', isBranded: false }}
      title={lsI18NService.t('welcome.title', { brand: displayName })}
    >
      <StyledLoadingContentWrapper>
        <TextWrapper>
          <Icon name="check-circle" size={sizing.s6} color={colors.primary} />
          <Text variant="titleMedium">{lsI18NService.t('welcome.service')}</Text>
        </TextWrapper>
        <TextWrapper>
          <Icon name="circle-outline" size={sizing.s6} color={colors.gray.secondary} />
          <Text variant="titleMedium">{lsI18NService.t('welcome.property')}</Text>
        </TextWrapper>
        <TextWrapper>
          <Icon name="circle-outline" size={sizing.s6} color={colors.gray.secondary} />
          <Text variant="titleMedium">{lsI18NService.t('welcome.details')}</Text>
        </TextWrapper>
      </StyledLoadingContentWrapper>
    </LoadingScreen>
  );
};
