import { gql } from 'graphql-request';
import {
  MutationAcceptEstimateArgs,
  MutationApplyPromoCodeArgs,
  MutationAttachServicesToExistingOrderArgs,
  MutationCreateProspectArgs,
  MutationRefreshAvailableServicesForProspectArgs,
  MutationStoreProspectPaymentTokenArgs,
  MutationSubmitProspectAsOrderArgs,
  PromoCode,
  Prospect,
  ProspectService,
  QueryGetPromoCodeArgs,
  QueryProspectArgs,
} from '../../../types/graphql';

export const ProspectFragmentDocument = gql`
  fragment ProspectFragment on Prospect {
    id
    authToken
    status
    lsApiPropertyId
    lsApiOrderId
    paymentBrand
    paymentLastFour
    paymentExpirationDate
    promoCode
    services {
      id
      preferredStartDate
      type
      selectedEstimate {
        id
        cycle
        amount
      }
      estimates {
        id
        cycle
        amount
      }
    }
    selectedServiceEstimates {
      id
      preferredStartDate
      type
      selectedEstimate {
        id
        cycle
        amount
      }
      estimates {
        id
        cycle
        amount
      }
    }
    contact {
      name
      phone
    }
  }
`;

export type CreateProspectMutationVariables = MutationCreateProspectArgs;
export type CreateProspectMutation = {
  __typename?: 'Mutation';
  createProspect?: {
    __typename?: 'CreateProspectOutput';
    prospect: Prospect;
    isPromocodeValid: boolean;
  } | null;
};
export const CreateProspectDocument = gql`
  mutation CreateProspect(
    $turnstileToken: String
    $brandId: String!
    $services: [ServiceTypeEnumScalar!]!
    $primaryIntent: ServiceTypeEnumScalar!
    $name: String!
    $phone: String!
    $address1: String!
    $city: String!
    $state: String!
    $zip: String!
    $country: String!
    $address2: String
    $promoCode: String
    $utmSource: String
    $utmMedium: String
    $utmTerm: String
    $utmContent: String
    $utmCampaign: String
    $fbcid: String
    $gclid: String
    $segmentAnonymousId: String
  ) {
    createProspect(
      turnstileToken: $turnstileToken
      brandId: $brandId
      services: $services
      primaryIntent: $primaryIntent
      name: $name
      phone: $phone
      address1: $address1
      city: $city
      state: $state
      zip: $zip
      country: $country
      address2: $address2
      promoCode: $promoCode
      utmSource: $utmSource
      utmMedium: $utmMedium
      utmTerm: $utmTerm
      utmContent: $utmContent
      utmCampaign: $utmCampaign
      fbcid: $fbcid
      gclid: $gclid
      segmentAnonymousId: $segmentAnonymousId
    ) {
      prospect {
        ...ProspectFragment
      }
      isPromocodeValid
    }
  }
  ${ProspectFragmentDocument}
`;

export type GetProspectQueryVariables = QueryProspectArgs;
export type GetProspectQuery = {
  __typename?: 'Query';
  prospect?: Prospect | null;
};
export const GetProspectDocument = gql`
  query GetProspect($id: HashID!) {
    prospect(id: $id) {
      ...ProspectFragment
      address {
        street
        city
        state
        zip
        country
        suiteNumber
      }
    }
  }
  ${ProspectFragmentDocument}
`;

export type AcceptEstimateMutationVariables = MutationAcceptEstimateArgs;
export type AcceptEstimateMutation = {
  __typename?: 'Mutation';
  acceptEstimate?: ProspectService | null;
  startDate?: ProspectService | null;
};
export const AcceptEstimateDocument = gql`
  mutation AcceptEstimate($estimateId: HashID!, $startDate: DateTime!) {
    acceptEstimate(estimateId: $estimateId, startDate: $startDate) {
      prospect {
        ...ProspectFragment
      }
    }
  }
  ${ProspectFragmentDocument}
`;

export type StoreProspectPaymentTokenMutationVariables = MutationStoreProspectPaymentTokenArgs;
export type StoreProspectPaymentTokenMutation = {
  __typename?: 'Mutation';
  storeProspectPaymentToken?: Prospect | null;
};
export const StoreProspectPaymentTokenDocument = gql`
  mutation StoreProspectPaymentToken($prospectId: HashID!, $paymentToken: String!) {
    storeProspectPaymentToken(prospectId: $prospectId, paymentToken: $paymentToken) {
      ...ProspectFragment
    }
  }
  ${ProspectFragmentDocument}
`;

export type SubmitProspectAsOrderMutationVariables = MutationSubmitProspectAsOrderArgs;
export type SubmitProspectAsOrderMutation = {
  __typename?: 'Mutation';
  submitProspectAsOrder?: Prospect | null;
};
export const SubmitProspectAsOrderDocument = gql`
  mutation SubmitProspectAsOrder($prospectId: HashID!, $contact: InputAccount!, $paymentToken: String!) {
    submitProspectAsOrder(prospectId: $prospectId, contact: $contact, paymentToken: $paymentToken) {
      ...ProspectFragment
    }
  }
  ${ProspectFragmentDocument}
`;

export type ApplyPromoCodeMutationVariables = MutationApplyPromoCodeArgs;
export type ApplyPromoCodeMutation = {
  __typename?: 'Mutation';
  applyPromoCode?: Prospect | null;
};
export const ApplyPromoCodeDocument = gql`
  mutation ApplyPromoCode($prospectId: HashID!, $promoCode: String!) {
    applyPromoCode(prospectId: $prospectId, promoCode: $promoCode) {
      id
    }
  }
`;

export type GetPromoCodeQueryVariables = QueryGetPromoCodeArgs;
export type GetPromoCodeQuery = {
  __typename?: 'Query';
  getPromoCode?: PromoCode | null;
};

export const GetPromoCodeDocument = gql`
  query GetPromoCode($id: String!) {
    getPromoCode(id: $id) {
      id
      type
      service
      amount
    }
  }
`;

export type RefreshAvailableServicesForProspectMutationVariables = MutationRefreshAvailableServicesForProspectArgs;
export type RefreshAvailableServicesForProspectMutation = {
  __typename?: 'Mutation';
  refreshAvailableServicesForProspect?: Prospect | null;
};

export const RefreshAvailableServicesForProspectDocument = gql`
  mutation RefreshAvailableServicesForProspect($prospectId: HashID!) {
    refreshAvailableServicesForProspect(prospectId: $prospectId) {
      ...ProspectFragment
    }
  }
  ${ProspectFragmentDocument}
`;

export type AttachServicesToExistingOrderMutationVariables = MutationAttachServicesToExistingOrderArgs;
export type AttachServicesToExistingOrderMutation = {
  __typename?: 'Mutation';
  attachServicesToExistingOrder?: Prospect | null;
};

export const AttachServicesToExistingOrderDocument = gql`
  mutation AttachServicesToExistingOrder($prospectId: HashID!, $serviceEstimates: [ProspectServiceEstimateInput!]!) {
    attachServicesToExistingOrder(prospectId: $prospectId, serviceEstimates: $serviceEstimates) {
      ...ProspectFragment
    }
  }
  ${ProspectFragmentDocument}
`;
