import { Accordion, Text } from '@lawnstarter/ls-react-common/atoms';
import { List } from 'react-native-paper';
import { lsI18NService } from '../../../../../service';
import { useTrackPayload } from '../../../../../hooks/useTrackPayload';
import { Events } from '../../../../../enums/events';
import { useCallback } from 'react';
import { trackHelpArticleExpanded } from '../../../../../service/segment/trackers';
import { PhoneDetailedBadge } from '../../../../../components/PhoneDetailedBadge';
import { StyledList, StyledListItem, StyledWrapperPhone } from './styles';

export const Questions = () => {
  const { payloadBuilder } = useTrackPayload(Events.HelpArticleExpanded);
  const onOpen = useCallback(
    (articleTitle: string) => {
      const payload = payloadBuilder({ articleTitle, helpContext: 'payment' });
      payload && trackHelpArticleExpanded(payload);
    },
    [payloadBuilder],
  );

  return (
    <>
      {[...Array(5).keys()].map((_, i) => (
        <Accordion
          title={lsI18NService.t(`accountAndPayment.faq.question${i + 1}.title`)}
          trackID={`faq-account-and-payment-${i}`}
          testID={`faq-account-and-payment-${i}`}
          key={i}
          onOpen={() => onOpen(lsI18NService.t(`accountAndPayment.faq.question${i + 1}.title`))}
        >
          <StyledList>
            <StyledListItem>
              <List.Item
                left={() => <Text>&#x25CF;</Text>}
                titleNumberOfLines={0}
                title={lsI18NService.t(`accountAndPayment.faq.question${i + 1}.content`)}
              />
            </StyledListItem>
          </StyledList>
        </Accordion>
      ))}
      <Accordion
        onOpen={() => onOpen(lsI18NService.t(`accountAndPayment.faq.moreQuestions.title`))}
        title={lsI18NService.t(`accountAndPayment.faq.moreQuestions.title`)}
        trackID={`faq-account-and-payment-more-questions`}
        testID={`faq-account-and-payment-more-questions`}
      >
        <StyledWrapperPhone>
          <PhoneDetailedBadge
            trackID="faq-account-and-payment-phone-detailed-badge"
            testID="faq-account-and-payment-phone-detailed-badge"
          />
        </StyledWrapperPhone>
      </Accordion>
    </>
  );
};
