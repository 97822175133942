import tw from 'tailwind-styled-components';

export const StyledAutocomplete = tw.div`
  w-full p-2 flex items-center justify-center border border-graySecondary bg-white
`;

export const StyledWrapper = tw.div`
  mt-6 text-center leading-3
`;

export const StyledInfoBox = tw.div`
  flex mt-6 justify-center items-center
`;

export const StyledLink = tw.a`
  ml-1 underline text-blue-500
`;
