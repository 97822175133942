import { Nullable } from '@lawnstarter/ls-react-common/types';
import { Events } from '../enums/events';

/**
 * Track Properties
 */
export interface AddressTracking {
  street: string;
  city: string;
  state: string;
  zip: string;
}

export type HelpContext = 'scheduling' | 'payment' | 'trust_safety' | 'lawn_treatment';

export enum CustomerSignupContext {
  FAQ = 'faq',
  HEADER = 'header',
}

export enum CustomerSignupAction {
  PAYMENT = 'payment',
  ACCOUNT = 'account',
}

export interface Price {
  service: string;
  frequency: number;
  price: string;
}

/**
 * Payload Builder Outputs
 */
export interface TrackCommonArgs {
  customer_signup_version?: Nullable<string>;
  channel?: Nullable<string>;
  customer_signup_page: string;
  prospect_hash_id: string;
  name: string;
  address: AddressTracking;
  primary_interest: string;
}
export interface TrackHelpViewedArgs extends TrackCommonArgs {
  help_context: HelpContext;
}

export interface TrackHelpArticleExpandedArgs extends TrackCommonArgs {
  selected_article: string;
  help_context: HelpContext;
}

export interface TrackStartDateSubmittedArgs extends TrackCommonArgs {
  start_date: string;
}

export interface TrackScheduleSubmittedArgs extends TrackCommonArgs {
  frequency: string;
  start_date: string;
  price: string;
  promocode: string;
}

export interface TrackPageViewedArgs extends TrackCommonArgs {
  promocode: string | null;
}

export interface TrackCrossSellSelectedArgs extends TrackCommonArgs {
  cross_sell: string[];
}

export interface TrackPromocodeAddedArgs extends TrackCommonArgs {
  promocode: string;
}

export interface TrackCallClickedArgs extends TrackCommonArgs {
  customer_signup_context: CustomerSignupContext | null;
}

export interface TrackActionCompletedArgs extends TrackCommonArgs {
  customer_signup_action: CustomerSignupAction;
  selected_frequency?: number;
  prices?: Price[];
  email?: string;
}

export interface TrackCompleteRegistrationArgs extends TrackCommonArgs {
  email: string;
}

export type TrackersArgs =
  | TrackHelpViewedArgs
  | TrackHelpArticleExpandedArgs
  | TrackScheduleSubmittedArgs
  | TrackCommonArgs
  | TrackPageViewedArgs
  | TrackStartDateSubmittedArgs
  | TrackCrossSellSelectedArgs
  | TrackPromocodeAddedArgs
  | TrackCallClickedArgs
  | TrackCompleteRegistrationArgs;

/**
 * Payload Builder Inputs
 */
export interface TrackHelpViewedInput {
  helpContext: HelpContext;
}

export interface TrackHelpArticleExpandedInput {
  articleTitle: string;
  helpContext: HelpContext;
}

export interface TrackStartDateSubmittedInput {
  startDate: string;
}

export interface TrackScheduleSubmittedInput {
  frequency: string;
  startDate: string;
  price: string;
  promocode: string;
}

export interface TrackPageViewedInput {
  promocode: string | null;
}

export interface TrackCrossSellSelectedInput {
  crossSell: string[];
}

export interface TrackPromocodeAddedInput {
  promocode: string;
}

export interface TrackCallClickedInput {
  signupContext: CustomerSignupContext | null;
}

export interface TrackActionCompletedInput {
  action: CustomerSignupAction;
  selectedFrequency?: number;
  prices?: Price[];
  email?: string;
}

export interface TrackCompleteRegistrationInput {
  email: string;
}

/**
 * Payload Builder Mapping
 */
export type PayloadBuilder = {
  [Events.HelpViewed]: (arg: TrackHelpViewedInput) => TrackHelpViewedArgs;
  [Events.HelpArticleExpanded]: (arg: TrackHelpArticleExpandedInput) => TrackHelpArticleExpandedArgs;
  [Events.ContactSubmitted]: () => TrackCommonArgs;
  [Events.OnAddressChange]: () => TrackCommonArgs;
  [Events.StartDateSubmitted]: (arg: TrackStartDateSubmittedInput) => TrackStartDateSubmittedArgs;
  [Events.NotLookingForClicked]: () => TrackCommonArgs;
  [Events.ScheduleSubmitted]: (arg: TrackScheduleSubmittedInput) => TrackScheduleSubmittedArgs;
  [Events.PageViewed]: (arg: TrackPageViewedInput) => TrackPageViewedArgs;
  [Events.CrossSellSelected]: (arg: TrackCrossSellSelectedInput) => TrackCrossSellSelectedArgs;
  [Events.PromocodeAdded]: (arg: TrackPromocodeAddedInput) => TrackPromocodeAddedArgs;
  [Events.CallClicked]: (arg: TrackCallClickedInput) => TrackCallClickedArgs;
  [Events.ActionCompleted]: (arg: TrackActionCompletedInput) => TrackActionCompletedArgs;
  [Events.InitiateCheckout]: () => TrackCommonArgs;
  [Events.CompleteRegistration]: (arg: TrackCompleteRegistrationInput) => TrackCompleteRegistrationArgs;
};
