import tw from 'tailwind-styled-components';

export const StyledLoadingContentWrapper = tw.div`
  flex flex-col gap-y-6 md:gap-y-[37px] mt-6 md:mt-[37px] mx-[56px] md:mx-[109px]
`;

export const TextWrapper = tw.div`
  flex flex-line items-center gap-x-2
`;

export const StyledCheckboxWrapper = tw.div`
  mb-8
`;
