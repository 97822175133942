import { QuoteQuestionFieldType } from '../../../enums/quoteQuestions';
import { StepId } from '../../../enums/schema';
import { useStepNavigation } from '../../../hooks/useStepNavigation';
import { lsI18NService } from '../../../service';
import { QuoteQuestionsStep } from '../../../types/schema';

const stepId = StepId.QuoteQuestions;

export const QuoteQuestions = () => {
  const { currentStep } = useStepNavigation({ stepId });
  const questions = (currentStep as QuoteQuestionsStep).options?.questions;

  // This HTML code will be tackled on https://lawnstarter.atlassian.net/browse/PE-46979
  return (
    <>
      {questions?.map((question, i) => {
        return (
          <div key={i} className="my-4">
            <span>{lsI18NService.t(`question.${question.label}.title`)}</span>
            {question.fields.map((field, j) => {
              return (
                <div key={j}>
                  <input type={field.type} placeholder={lsI18NService.t(`question.${question.label}.${field.key}`)} />
                  {QuoteQuestionFieldType.Checkbox === field.type && (
                    <span className="ml-2">{lsI18NService.t(`question.${question.label}.${field.key}`)}</span>
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};
