import { Icon, Text } from '@lawnstarter/ls-react-common/atoms';

import { lsI18NService } from '../../../../../service';
import { StyledItem, StyledList, titleStyle } from './styles';

export const TreatmentSummary = () => {
  return (
    <>
      <Text variant="titleMedium" style={titleStyle}>
        {lsI18NService.t('lawnTreatment.firstSection.title')}
      </Text>
      <Text variant="bodyMedium">{lsI18NService.t('lawnTreatment.firstSection.description')}</Text>
      <StyledList>
        <StyledItem>
          <Icon color="home" name="card-search-outline" size={20} />
          <Text variant="bodyMedium">{lsI18NService.t('lawnTreatment.firstSection.advantages.assesmentByPro')}</Text>
        </StyledItem>
        <StyledItem>
          <Icon color="home" name="star-circle-outline" size={20} />
          <Text variant="bodyMedium">{lsI18NService.t('lawnTreatment.firstSection.advantages.freeTouchUps')}</Text>
        </StyledItem>
        <StyledItem>
          <Icon color="home" name="bone" size={20} />
          <Text variant="bodyMedium">{lsI18NService.t('lawnTreatment.firstSection.advantages.safeForPets')}</Text>
        </StyledItem>
      </StyledList>
    </>
  );
};
