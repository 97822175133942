import { ReactNode } from 'react';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon, Text } from '@lawnstarter/ls-react-common/atoms';
import MaterialIcons from 'react-native-vector-icons/glyphmaps/MaterialCommunityIcons.json';

import { StyledHeader, StyledMain, StyledWrapper, titleStyle } from './styles';

type MaterialIcon = keyof typeof MaterialIcons;

interface BannerProps {
  title: string;
  children: ReactNode;
  icon: MaterialIcon;
  headerSlot?: () => JSX.Element;
  headerClassName?: string;
  className?: string;
}

export const Banner = ({ title, children, icon, headerSlot, headerClassName, className }: BannerProps) => {
  const { sizing, colors } = useAppTheme();

  return (
    <StyledWrapper className={className}>
      <StyledHeader className={headerClassName}>
        <Icon name={icon} color={colors.gray.tertiary} size={sizing.s6} />
        <Text style={titleStyle} variant="titleMedium">
          {title}
        </Text>
        {headerSlot && headerSlot()}
      </StyledHeader>
      <StyledMain>
        <Text style={{ color: colors.gray.tertiary }}>{children}</Text>
      </StyledMain>
    </StyledWrapper>
  );
};
