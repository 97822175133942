import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';

import App from './App';
import { initBrand, initSentry } from './init';
import { store } from './store';
import { VITE_FLAGSMITH_KEY } from './config/env';

initSentry();
initBrand();

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <FlagsmithProvider options={{ environmentID: VITE_FLAGSMITH_KEY }} flagsmith={flagsmith}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </FlagsmithProvider>
  </StrictMode>,
);
