import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectContact } from '../store/modules/contact/slice';
import { selectProperty } from '../store/modules/property/slice';
import { Status as ProspectStatus } from '../enums/prospect';
import { StepId, StepType } from '../enums/schema';
import { selectProspect } from '../store/modules/prospect/slice';
import { VITE_MY_LOGIN_URL } from '../config/env';
import { Logs } from '../enums/logs';
import { lsLogger } from '../helpers/logger';

export const verticalSelectionStep = `/${StepType.Cart}/${StepId.VerticalSelection}`;
export const contactInfoStep = `/${StepType.Cart}/${StepId.ContactInfo}`;

export const useCheckValidState = () => {
  const navigate = useNavigate();
  const contact = useSelector(selectContact);
  const property = useSelector(selectProperty);
  const prospect = useSelector(selectProspect);
  const { hashId: prospectId } = useParams();
  const { pathname } = useLocation();

  // Global redirection chained rules
  useEffect(() => {
    const nonNavigableRoutes = [
      verticalSelectionStep,
      contactInfoStep,
      `/${StepType.Cart}/${prospectId}/${StepId.ExtraServices}`,
      `/${StepType.Cart}/${prospectId}/${StepId.LawnTreatment}`,
      `/${StepType.Cart}/${prospectId}/${StepId.AccountAndPayment}`,
      `/${StepType.Cart}/${prospectId}/${StepId.Welcome}`,
      `/${StepType.Cart}`,
      `/`,
    ];

    if (!prospect.id || nonNavigableRoutes.includes(pathname)) {
      lsLogger(Logs.NonNavigableRoutes);
      return;
    } else if (prospect.status === ProspectStatus.Completed) {
      lsLogger(Logs.StatusCompleted);
      window.location.replace(VITE_MY_LOGIN_URL);
    } else if (prospect.status === ProspectStatus.Transferred) {
      lsLogger(Logs.StatusTransferred);
      navigate({ pathname: contactInfoStep });
    } else if (!contact.name || !property.address1) {
      lsLogger(Logs.InvalidState);
      navigate({ pathname: contactInfoStep });
    }
  }, [contact, property, prospect, navigate, pathname, prospectId]);
};
