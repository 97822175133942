import { Icon } from '@lawnstarter/ls-react-common/atoms';
import { StyledWrapperChevron } from './styles';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

export interface BackButtonProps {
  className?: string;
  pathname?: string;
}

export const BackButton = ({ className, pathname }: BackButtonProps) => {
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    if (pathname) {
      navigate({ pathname });
    } else {
      window.history.back();
    }
  }, [navigate, pathname]);

  return (
    <StyledWrapperChevron onClick={onClick} data-testid="back-button" className={className}>
      <Icon color="black" name="arrow-left" size={26} />
    </StyledWrapperChevron>
  );
};
