import { AnalyticsBrowser } from '@segment/analytics-next';
import { VITE_SEGMENT_KEY } from '../../config/env';

export const SegmentService = () => {
  const analytics = AnalyticsBrowser.load({ writeKey: VITE_SEGMENT_KEY });

  return analytics;
};

export const lsSegmentService = SegmentService();
