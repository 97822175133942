import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectProspect } from '../../../store/modules/prospect/slice';
import { ProspectService } from '../../../types/graphql';
import { CartItem } from '../../../store/modules/cart/slice';
import { ExtraServicesStep } from '../../../types/schema';

interface Props {
  currentStep: ExtraServicesStep;
}

export const useExtraServices = ({ currentStep }: Props) => {
  const prospect = useSelector(selectProspect);

  // Filter out services that are eligible for the extra services
  const extraServices: CartItem[] = useMemo(() => {
    const isEligible = (service: ProspectService) => currentStep.services?.includes(service.type);
    const eligibleServices = prospect.services?.filter(isEligible) || [];
    return eligibleServices.reduce(
      (acc, service) =>
        service.estimates?.length
          ? acc.concat([{ type: service.type, estimateId: String(service.estimates[0].id) }])
          : acc,
      [] as CartItem[],
    );
  }, [currentStep.services, prospect.services]);

  // Dynamic default values for the Hook Form
  const defaultValues: Record<string, boolean> = useMemo(() => {
    return extraServices?.reduce((acc, service) => {
      acc = { ...acc, [service.type]: false };
      return acc;
    }, {});
  }, [extraServices]);

  return { defaultValues, extraServices };
};
