import { configureStore, ConfigureStoreOptions, isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';

import { lsApi } from '../service/lsApi/lsApi';

import app, { updateApp } from './modules/app/slice';
import contact from './modules/contact/slice';
import property from './modules/property/slice';
import prospect from './modules/prospect/slice';
import cart from './modules/cart/slice';

export const rtkQueryErrorLogger: Middleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    // istanbul ignore next
    if (isRejectedWithValue(action)) {
      const { message } = action.payload as { message: string };
      const error = message.split('.')[0];
      Sentry.captureMessage(message);
      dispatch(updateApp({ error }));
    }

    return next(action);
  };

export const createStore = (options?: ConfigureStoreOptions['preloadedState'] | undefined) =>
  configureStore({
    reducer: {
      [lsApi.reducerPath]: lsApi.reducer,
      app,
      contact,
      property,
      prospect,
      cart,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(lsApi.middleware, rtkQueryErrorLogger),
    ...options,
  });

export const store = createStore();

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
