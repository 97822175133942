import { Spinner } from '@lawnstarter/ls-react-common/atoms';
import { StyledAutocomplete } from './styles';

export const AutocompleteSpinner = () => {
  return (
    <StyledAutocomplete>
      <Spinner size={32} testID="autocomplete-loading" />
    </StyledAutocomplete>
  );
};
