import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { VITE_API_BASE_URL } from '../../config/env';

export const lsApi = createApi({
  baseQuery: graphqlRequestBaseQuery({
    url: VITE_API_BASE_URL,
    prepareHeaders: (headers) => {
      // TODO: Add Bearer token
      // headers.set('foo', 'bar');
      return headers;
    },
  }),
  endpoints: () => ({}),
});
